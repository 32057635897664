#content {
    .pagination {

        li>span,
        li>a {
            background-color: transparent !important;
            border          : none !important;
            color           : #000000 !important;
        }

        li.active>span,
        li.active>a {
            font-weight: bold;
        }
    }

    .has-table {
        overflow: auto;

        &:first-child {
            margin-top: 73px;
        }

        td,
        th {
            vertical-align: middle;
        }

        td {
            vertical-align: middle;
            text-align    : left;
            padding       : 8px 20px;

            .select2-container{
                padding: 0.3rem 1rem;
                .select2-choice{
                    border : none;
                    background-color: transparent;
                    background-image: none;
                    display: flex;
                    align-items: center;
                    padding: 0;
                    width: 110px;

                    .select2-chosen{
                        font-family: $default-font-stack;
                        font-size: 14px;
                    }
                }
                &.select2-container-active{
                    .select2-choice{
                        box-shadow: none;
                    }
                }
                .select2-arrow{
                    border : none;
                    background-color: transparent;
                    background-image: none;
                    display: grid;
                    place-content: center;

                    &:before{
                        content: url("../img/dropdown-caret.svg");
                        transform: rotate(0deg);
                    }

                    b{
                        display: none;
                    }
                }
                &.select2-dropdown-open{
                    outline: 1px solid #E9E9E9;
                    .select2-arrow:before{
                        transform: rotate(180deg);
                        transition: transform 0.5s;
                    }
                }
            }

            &.content-status{
                width: 0;
            }
        }
    }
}

.template-full_view,
.template-tabular_view{
    #select2-drop{
        border: 1px solid #E9E9E9;
        color: #6D6D6D;
        transform: translateY(5px);
        border-radius: 2px;
        .select2-results{
            margin: 0;
            padding: 0;
            text-align: left;
        }
        .select2-search{
            display: none;
        }

        li.select2-result{

            .select2-result-label{
                font-family: $default-font-stack-roman;
                font-size: 14px;
                padding: 0.3rem 1rem;

            }
            &:first-child{
                display: none;
            }
        }

        li.select2-highlighted{
            background-color: #EFEFEF;
            color: #6D6D6D;
        }
    }
}
