.navbar {
  padding: 0;
}

.navbar-nav {
  .nav-item {
    .nav-link {
      padding: 20px 0 16px 0;

      &+.dropdown-menu {
        max-height: 50vh;
        overflow  : auto;
      }
    }
  }
}

.nav-item {
  font-size: 12px;
  margin   : 0 2em;

  .nav-link {
    border-bottom: 5px solid transparent;
    height       : 100%;
    font-family  : $navigation-font-stack;

    &:focus {
      color: #000;
    }
  }

  &:hover,
  &.active,
  &.current {
    .nav-link {
      border-bottom: 5px solid #000;
      color        : #000;
    }
  }
}

.dropdown-menu {
  font-family  : $default-font-stack;
  padding      : 0;
  border       : none;
  border-radius: 0;
  margin-left  : 1.5rem;
  margin-top   : 0;
  box-shadow   : 0 12px 10px 0px rgba(0, 0, 0, 0.08);
  min-width    : 13rem;
}

.dropdown-item {
  font-size  : rem(12px);
  padding    : 1rem 1.8rem !important;
  text-align : left !important;
  font-weight: normal;
  font-family: $default-font-stack-roman;
  white-space: normal;

  &:hover {
    background-color: #000;
    color           : #fff;
  }
}

// sidenav

.template-big_thumbnails {

  #content,
  #content-alert {
    margin-left: 0;
    transition : all 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
  }

  &.portlet-opened {

    #content,
    #content-alert {
      width: 100%;
    }
  }
}

.template-small_thumbnails,
.template-facetednavigation_view,
.template-video_view,
.template-flowplayer,
.template-listing_view,
.template-folder {

  #content-alert,
  #content,
  #content-core,
  #breadcrumb {

    /* 0.5 second transition effect to slide in the sidenav */
    @media (max-width: 767px) {
      & {
        width       : 100%;
        margin-left : 0;
        margin-right: 0;
        padding     : 0 15px;
        line-height : 16px;
      }
    }
  }

  &.portlet-opened {

    #content,
    #content-alert,
    #content-core,
    #breadcrumb {
      width: 100%;
    }

    .card-list {
      .card {
        border       : none;
        padding-left : 5px;
        padding-right: 5px;
      }
    }
  }
}

.breadcrumb {
  padding-top: 24px;
}

.template-facetednavigation_view,
.template-twofold_view,
.template-small_thumbnails {
  #breadcrumb {
    &.container-fluid {
      padding-left: 25px;
    }
  }

  &.portlet-opened {
    #breadcrumb {
      &.container-fluid {
        padding-left: 25px;
      }
    }

    .card-list {
      .card {
        border       : none;
        padding-left : 15px;
        padding-right: 15px;
      }
    }
  }
}

.template-big_thumbnails {

  #content-alert,
  #content,
  #breadcrumb {
    width       : 100%;
    transition  : all 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    margin-left : 0;
    margin-right: 0;
  }

  &.portlet-opened {

    #content,
    #content-alert,
    #breadcrumb {
      width       : 100%;
      margin-right: 0px;
    }

    .card-list {
      .card {
        border       : none;
        padding-left : 5px;
        padding-right: 5px;
      }
    }
  }
}

#portlet-toggler,
#setting-toggler {
  appearance      : none;
  border          : 0;
  background-color: transparent;
  padding         : 0;
  padding-bottom  : 29px;

  svg {
    transition: 0.5s;
  }

  &:focus,
  &:hover {
    box-shadow: none;
    outline   : none;
  }

  .portlet-opened & {
    svg {
      transform : rotate(90deg);
      transition: 0.5s;
    }
  }
  
}

#portlet-toggler svg{
    stroke : black;
}

.template-facetednavigation_view {
  #portlet-toggler,
  #setting-toggler {
    box-shadow: none;
  }

  #portlet-toggler{
    &.active svg {
      transform : rotate(90deg);
      stroke : black;
    }
    
    &:not(.active) svg {
      stroke : #C5C5C5;
    }

  }
  #setting-toggler{
    &.active svg{
      transform : rotate(180deg);
      fill : black;
    }
    &:not(.active) svg {
      fill: #C5C5C5;
    }
    @media (max-width: 767px) {
      padding-bottom: 5px;
    }
  }
}


.burger-portlet-toggler {
  z-index: 2;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F8F8F8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D8D8D8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D8D8D8;
}

.sidenav {
  position           : relative;
  width              : 0;
  z-index            : 1;
  background-color   : #fff;
  overflow-x         : hidden;
  transition-duration: 0.5s;
  transition-delay   : 0.5s;

  .collapse.in .collapse:not(.in) {
    display: block;
  }

  .nav,
  #portal-column-one {
    padding            : 2.5rem 1.5rem 0 2.5rem;
    opacity            : 0;
    width              : 100%;
    transition-duration: 0.5s;
    transition-delay   : 0;
    position           : relative;
  }

  #portal-column-one {
    padding: 1rem 1.5rem 0 2.5rem;

    .portletWrapper {
      display: none;
    }
  }

  &.active {
    left               : 0;
    width              : 20vw;
    transition-duration: 0.5s;
    transition-delay   : 0s;

    .nav,
    #portal-column-one {
      opacity            : 1;
      transition-duration: 0.5s;
      transition-delay   : 0.5s;

      .portletWrapper {
        transition-delay: 0.5s;
        display         : inline-block;
      }
    }
  }

  &:not(.active) {
    .nav-item {
      display: none;
    }
  }

  .nav-dropdown-items {
    margin-top : 0.5rem;
    margin-left: 0.5rem;
    padding    : 0;

    .nav-item {
      font-family   : $default-font-stack;
      padding-bottom: 0.5em;
    }
  }

  .nav-item {
    font-family: 'Helvetica Neue LT Std 65 Medium';
    font-size  : rem(14);
    text-align : left;
    padding    : 0 0 1rem;
    margin     : 0;

    a {
      width      : 100%;
      display    : block;
      white-space: normal;
      position   : relative;

      &:hover {
        transition     : .5s;
        color          : #484848;
        text-decoration: none;
      }

      &.dropdown-toggle {
        padding-right: 2rem;

        &::after {
          background: url("../img/dropdown-caret.svg") no-repeat center center;
          border    : none;
          width     : 15px;
          height    : 15px;
          transition: 0.3s;
          display   : inline-block;
          position  : absolute;
          top       : 0;
          right     : 0;
        }

        &[aria-expanded="true"] {
          &::after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  @media (max-width: 767px) {

    width              : 100%;
    height             : 0;
    transition-delay   : 0s;
    transition-duration: 0s;
    opacity            : 1;

    &.active {
      width              : 100%;
      height             : auto;
      transition-delay   : 0s;
      transition-duration: 0s;
    }

    .nav {
      position           : relative;
      opacity            : 1;
      transition-delay   : 0s;
      transition-duration: 0s;
      padding            : 1.5rem 0;

      .nav-item {
        padding: 0;

        a {
          padding: 0.5rem 1rem;
          border : 1px solid rgba(0, 0, 0, 0);

          &:hover,
          &:active,
          &:focus {
            border: 1px solid rgba(0, 0, 0, .1);
          }
        }
      }
    }
  }
}

.icon-search {
  &:before {
    display: none !important;
  }
}

// Transitions hack

.collapse {
  @include transition($transition-collapse);

  &:not(.in) {
    display: none;
    height : auto;
  }
}

.collapsing {
  position: relative;
  //height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}

// Card view

.card {}

@media (max-width: 767px) {
  #portlet-toggler {
    padding: 5px 0;
  }
}