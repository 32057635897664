@charset "UTF-8";
@import url(barceloneta.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #000;
  --secondary: #CCC;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.15;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #000;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.15em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.15;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #404040;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.15;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.15;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.15;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.15em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.2875em + 0.1875rem) center;
  background-size: calc(0.575em + 0.375rem) calc(0.575em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.15em + 0.75rem);
  background-position: top calc(0.2875em + 0.1875rem) right calc(0.2875em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.575em + 0.375rem) calc(0.575em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.15;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.15em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.2875em + 0.1875rem) center;
  background-size: calc(0.575em + 0.375rem) calc(0.575em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.15em + 0.75rem);
  background-position: top calc(0.2875em + 0.1875rem) right calc(0.2875em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.575em + 0.375rem) calc(0.575em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.15;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-primary:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #CCC;
  border-color: #CCC;
}

.btn-secondary:hover {
  color: #212529;
  background-color: #b9b9b9;
  border-color: #b3b3b3;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #b9b9b9;
  border-color: #b3b3b3;
  box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #CCC;
  border-color: #CCC;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #acacac;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #000;
  border-color: #000;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-secondary {
  color: #CCC;
  border-color: #CCC;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #CCC;
  border-color: #CCC;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #CCC;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #CCC;
  border-color: #CCC;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #000;
  text-decoration: none;
}

.btn-link:hover {
  color: #000;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #000;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.35625rem;
  padding-bottom: 0.35625rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}

.alert-primary hr {
  border-top-color: #ababab;
}

.alert-primary .alert-link {
  color: black;
}

.alert-secondary {
  color: #6a6a6a;
  background-color: whitesmoke;
  border-color: #f1f1f1;
}

.alert-secondary hr {
  border-top-color: #e4e4e4;
}

.alert-secondary .alert-link {
  color: #515151;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-secondary {
  color: #6a6a6a;
  background-color: #f1f1f1;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #6a6a6a;
  background-color: #e4e4e4;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.15;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.15;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.15;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #000 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: black !important;
}

.bg-secondary {
  background-color: #CCC !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #b3b3b3 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #000 !important;
}

.border-secondary {
  border-color: #CCC !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #000 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: black !important;
}

.text-secondary {
  color: #CCC !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #a6a6a6 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

@font-face {
  font-family: 'ABChanel Corpo_Extra Light';
  src: url("../fonts/ABChanel_Corpo-Extra_Light_web.woff") format("woff"), url("../fonts/ABChanel_Corpo-Extra_Light_web.woff2") format("woff2"), url("../fonts/ABChanel_Corpo-Extra_Light_web.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABChanel Corpo_Light';
  src: url("../fonts/ABChanel_Corpo-Light_web.woff") format("woff"), url("../fonts/ABChanel_Corpo-Light_web.woff2") format("woff2"), url("../fonts/ABChanel_Corpo-Light_web.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABChanel Corpo_Regular';
  src: url("../fonts/ABChanel_Corpo-Regular_web.woff") format("woff"), url("../fonts/ABChanel_Corpo-Regular_web.woff2") format("woff2"), url("../fonts/ABChanel_Corpo-Regular_web.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABChanel Corpo_Semi Bold';
  src: url("../fonts/ABChanel_Corpo-Semi_Bold_web.woff") format("woff"), url("../fonts/ABChanel_Corpo-Semi_Bold_web.woff2") format("woff2"), url("../fonts/ABChanel_Corpo-Semi_Bold_web.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Std 57 Condensed';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueLTStd-Cn.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue LT Std 56 Italic';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueLTStd-It.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue LT Std 47 Light Condensed';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueLTStd-LtCn.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue LT Std 45 Light';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueLTStd-Lt.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue LT Std 77 Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueLTStd-BdCn.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue LT Std 65 Medium';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueLTStd-Md.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue LT Std 55 Roman';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Helvetica-Neue-LT-Std-55-Roman_22526.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueBold.woff") format("woff");
}

@font-face {
  font-family: 'Helvetica Neue LT Std 75 Bold';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/HelveticaNeueBold.woff") format("woff");
}

.gotop {
  position: fixed;
  padding: 10px 28px;
  z-index: 100;
  left: 45%;
  bottom: 0;
  font-size: 18px;
  border-radius: 36px 36px 0 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 3px 10px 3px rgba(255, 255, 255, 0.4);
  display: none;
}

.gotop:hover {
  color: #fff;
  background: black;
  text-decoration: none;
  text-shadow: 0 0 5px #fff;
}

.breadcrumb {
  background-color: transparent;
  font-size: 12px;
  padding-top: 26px;
  padding-bottom: 8px;
  margin-bottom: 0;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .breadcrumb {
    display: none;
  }
}

main {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

main h1,
main h1.documentFirstHeading {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 14px;
  margin-top: 13px;
  padding-bottom: 13px;
}

main #main-content {
  width: 80%;
  margin: 0 auto;
}

main #main-content #content-block {
  display: flex;
}

main #main-content #content-block #content {
  flex: 1;
  max-width: 100%;
}

@media (max-width: 767px) {
  main #main-content #content-block {
    flex-direction: column;
  }
  main #main-content #content-block #content-video.aside-right {
    max-width: 100%;
  }
  main #main-content #content-block #content-video.aside-right .section.clearfix {
    padding-bottom: 0;
  }
  main #main-content #content-block #portal-column-two {
    padding: 0;
    width: 100%;
  }
}

main .documentDescription {
  font-size: 16px;
  color: #000;
  font-weight: normal;
}

@media (max-width: 767px) {
  main {
    flex-direction: column;
  }
}

.portaltype-document #content-block,
.portaltype-troubleticket #content-block,
.portaltype-collection #content-block,
.portaltype-file #content-block,
.portaltype-folder #content-block {
  margin-top: 30px;
}

h1 a:focus,
h1 a:hover,
h2 a:focus,
h2 a:hover,
h3 a:focus,
h3 a:hover {
  border-bottom: none;
}

a:focus,
a:hover {
  color: #000000;
}

p {
  letter-spacing: 0.025rem;
}

#searchform .input-group {
  display: flex;
  width: 100%;
  max-width: none;
}

#searchform .input-group .searchPage.form-control {
  float: none;
  flex: 1;
}

#searchform .input-group .searchPage.form-control + .input-group-btn {
  width: auto;
  border: 1px solid #000000;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  height: 34px;
}

#searchform #search-results-bar #results-count {
  float: none;
  margin: 1rem 0;
  display: inline-block;
}

#searchform .actionMenu {
  float: none;
  position: relative;
  margin-bottom: 2rem;
}

#searchform .actionMenu .actionMenuHeader {
  position: relative;
}

#searchform .actionMenu .actionMenuHeader #search-filter-toggle {
  width: 100%;
  margin-top: 0.5rem;
}

#searchform .actionMenu .actionMenuContent {
  top: 100%;
}

#searchform #search-results ol {
  padding: 0;
}

#searchform #search-results ol li {
  text-align: left;
  margin-left: 1rem;
}

#searchform #search-results ol li .croppedDescription {
  margin-top: 0.5rem;
}

.tag-list:not(:empty) {
  margin: 0.8rem 0 0 0;
  text-align: left;
}

.tag-list:not(:empty) li {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 12px;
  display: inline-block;
}

.tag-list:not(:empty) li:before {
  content: '#';
}

.tag-list:not(:empty) li a {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 12px;
  background-color: transparent;
  text-transform: uppercase;
  padding: 0;
  margin-left: -2px;
}

.tag-list:empty {
  padding: 0;
  margin: 0;
}

body.portaltype-video .title-video {
  font-size: 20px !important;
  line-height: 22px !important;
}

.title-parent,
.title-video {
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  text-align: left;
  margin-bottom: 0.8rem;
  font-size: 30px;
  line-height: 33px;
}

.description-video {
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 22px;
  text-align: left;
  letter-spacing: 0.025rem;
}

.aside {
  text-align: left;
}

.portletStaticText.omit-border .portletContent p a,
.download-button {
  display: flex;
  align-items: center;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  padding-top: 20px;
  text-align: start;
}

.portletStaticText.omit-border .portletContent p a:focus,
.download-button:focus {
  outline: none;
}

.portletStaticText.omit-border .portletContent p a:before,
.download-button:before {
  content: '';
  width: 30px;
  height: 30px;
  display: inline-flex;
  margin-right: 10px;
  background: url(../img/download.svg) no-repeat;
  background-size: 30px;
  background-color: black;
  background-position: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 30px;
}

.portletStaticText.omit-border .portletContent p a:hover,
.download-button:hover {
  color: #000;
}

.portletStaticText.omit-border .portletContent p a:hover:before,
.download-button:hover:before {
  opacity: 0.9;
}

.validation {
  align-items: flex-end;
}

.validation .custom-checbox {
  border: 1px solid #000;
  appearance: none;
}

.validation div {
  align-self: flex-end;
}

.circle-state {
  background-color: #cccccc;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.circle-state.pending {
  background-color: #cccccc;
  color: #fff;
}

.circle-state.rejected {
  background-color: #8E000D;
  color: #fff;
}

.circle-state.validated {
  background-color: #2B5A36;
  color: #fff;
}

.portaltype-plone-site .portalMessage {
  display: block;
  border-radius: 0;
  box-shadow: none;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  line-height: normal;
  padding: 10px;
  text-shadow: none;
}

.portaltype-plone-site .portalMessage strong {
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
}

.portaltype-plone-site .portalMessage.info {
  background-color: #F1F1F1;
  border: none;
}

.portaltype-plone-site .portalMessage.info > strong::before {
  content: "";
  background: url(../img/info.svg) no-repeat;
  background-size: 20px;
  background-position: center;
  display: inline-flex;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 20px;
  margin-right: 0.5rem;
  top: 4px;
}

.custom-control label {
  cursor: pointer;
}

.checkbox-button {
  cursor: pointer;
}

input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

input:active, input:focus, input:hover {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.custom-checkbox-square {
  opacity: 0;
  position: absolute;
}

.custom-checkbox-square__control {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 5px;
  vertical-align: middle;
  background-color: inherit;
  color: #000;
  border: 1px solid #C1C1C1;
}

.custom-checkbox-square:checked + .custom-checkbox-square__control:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: #000;
}

.custom-checkbox-square:checked + .custom-checkbox-square__control {
  border-color: #000;
}

.custom-checkbox-square__control {
  transform: scale(0.75);
}

.custom-checkbox-square__label {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  font-weight: normal;
}

.template-edit #content .documentFirstHeading {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 18px;
}

.template-edit #content #content-core {
  text-align: left;
}

.template-edit #content #content-core #form {
  margin: 0;
}

b,
strong {
  font-weight: bold;
}

#content-alert .alert {
  top: 10px;
  padding: .75rem 0;
}

.navbar-toggler {
  display: none;
}

.spinner {
  display: none;
}

.template-login-help #breadcrumb {
  display: none;
}

.template-login #breadcrumb {
  display: none;
}

.template-login #login-form {
  margin: 80px auto;
  max-width: 380px;
}

.template-login .footer {
  margin-top: 4rem;
}

.template-login .footer a {
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  text-decoration: underline;
  font-size: 14px;
}

.formControls {
  border-top: none;
  background: transparent;
  padding: 12px;
  box-shadow: none;
}

.list-unstyled {
  list-style-type: none;
  padding-left: 0;
}

a:focus,
a:active,
a:hover,
a:visited {
  outline: none !important;
  outline-offset: 0 !important;
}

.modal-content {
  border-radius: 0;
}

.modal-content .modal-body {
  padding: 3rem;
}

.modal-content .close {
  font-size: 2.5rem;
  opacity: .8;
  position: absolute;
  right: 20px;
  top: 20px;
}

.modal-content .close span {
  -webkit-text-stroke: 0.5px white;
  font-size: 50px;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
}

.modal-content .close:hover, .modal-content .close:active {
  box-shadow: none;
  opacity: 1;
}

.modal-content h4 {
  margin-bottom: 0.5rem;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 16px;
}

.modal-content ul {
  margin-bottom: 1rem;
}

.modal-content ul li {
  line-height: 18px;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
  min-height: 20px;
}

.owl-prev, .owl-next {
  color: rgba(255, 255, 255, 0) !important;
}

.owl-theme .owl-nav [class*='owl-'] {
  background-color: transparent;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background-color: red;
}

.owl-theme .owl-nav {
  margin-top: 0px !important;
}

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  margin: 0px !important;
  display: block !important;
  border: 0px solid black;
  background-color: #fff !important;
}

.owl-theme .owl-nav .owl-prev img,
.owl-theme .owl-nav .owl-next img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
}

.owl-theme .owl-nav .owl-prev img {
  left: 0px;
}

.owl-theme .owl-nav .owl-next {
  right: 0;
}

.owl-theme .owl-nav .owl-next img {
  right: 0px;
}

.hide {
  display: none;
}

#login-form span.required {
  display: none;
}

#edit-zone nav > ul ul li.plone-toolbar-submenu-header > span,
#edit-zone nav > ul ul li.plonetoolbar-display-view.actionSeparator > span {
  display: none;
}

#login-form .formControls {
  padding: 12px 0;
}

#login-form label {
  font-size: 14px;
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  font-weight: normal;
}

#login-form #buttons-login {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-weight: normal;
  font-size: 12px;
  min-width: 100%;
}

#login-form #buttons-login:hover, #login-form #buttons-login:active, #login-form #buttons-login:focus {
  background-color: #000;
  color: #FFFFFF;
  border: 1px solid #000;
}

#login-form #buttons-login:hover, #login-form #buttons-login:focus, #login-form #buttons-login:active {
  opacity: 0.7;
}

#login-form #external-login, #login-form #external-login:hover, #login-form #external-login:focus, #login-form #external-login:active {
  border: 1px solid transparent;
  border-radius: 0;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  box-shadow: none;
  text-shadow: none;
  padding: 10px 20px;
  font-weight: normal;
  background-color: #000;
  color: #FFFFFF;
  border: 1px solid #000;
  display: block;
  margin-top: 10px;
  min-width: 100%;
  text-decoration: none;
}

#login-form #external-login:hover, #login-form #external-login:focus, #login-form #external-login:active {
  opacity: 0.7;
}

#login-form #login-or {
  margin-top: 10px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .validation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }
  .validation div {
    padding: 0;
    flex: 1;
    width: auto;
    align-self: center;
  }
  .download-button {
    padding-top: 10px;
  }
  #content #video-comments-container + div {
    padding: 0;
  }
  #searchform #searchResultsSort {
    max-height: none;
    overflow: auto;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  #searchform #searchResultsSort #sorting-options {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
  #searchform #searchResultsSort #sorting-options a {
    margin: 0 0.5rem;
  }
  #searchform .input-group {
    flex-direction: column;
  }
  #searchform .input-group .searchPage {
    margin-bottom: 0.5rem;
  }
  #searchform .input-group .input-group-btn {
    border-radius: 4px;
  }
  #searchform .input-group .input-group-btn .allowMultiSubmit.btn {
    width: 100%;
    margin-left: 0;
    border-radius: 4px;
  }
}

body:not(.userrole-manager) .state-private,
body:not(.userrole-manager) .state-pending {
  color: #000 !important;
}

body:not(.userrole-manager) .header .dropdown-menu a:focus,
body:not(.userrole-manager) .header .dropdown-menu a:hover {
  background-color: #000;
  color: #fff !important;
}

.documentAuthor,
.documentModified {
  display: none;
}

.modal-open .modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-dialog {
  height: 100%;
  margin: 0 auto !important;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-dialog .modal-header {
  border: none;
  padding: 0;
}

.modal-dialog .modal-content {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 9px 0 rgba(0, 0, 0, 0.1);
}

.modal-dialog .modal-content .modal-body {
  padding: 50px;
}

.modal-dialog .modal-content .close {
  font-size: 2.5rem;
  opacity: .8;
  position: absolute;
  right: 20px;
  top: 25px;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.modal-dialog .modal-content .close::before {
  content: "×";
  display: block;
  line-height: 25px;
  -webkit-text-stroke: 0.4px white;
  font-size: 50px;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
}

.modal-dialog .modal-content .close:hover, .modal-dialog .modal-content .close:active {
  box-shadow: none;
  opacity: 1;
}

.modal-dialog .modal-content .close span {
  display: none;
}

.modal-dialog .modal-content .close:hover, .modal-dialog .modal-content .close:active {
  box-shadow: none;
  opacity: 1;
}

.modal-dialog .modal-content h4 {
  margin-bottom: 0.5rem;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 16px;
}

.modal-dialog .modal-content ul {
  margin-bottom: 1rem;
}

.modal-dialog .modal-content ul li {
  line-height: 18px;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
  min-height: 20px;
}

label .required::after {
  color: #c4183c;
  content: "•";
  font-size: 20px;
  line-height: 0;
  position: relative;
  top: 3px;
  margin-left: -4px;
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
}

.portaltype-document .leadImage {
  display: none;
}

.header {
  padding: 2rem 1rem 0 1rem;
  background: #FFFFFF;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 11;
  min-height: 140px;
}

.plone-toolbar-left-default .header.fixed-top {
  margin-left: 60px;
}

@media (max-width: 767px) {
  .plone-toolbar-left-default .header.fixed-top {
    margin-left: 0px;
  }
}

@media (max-width: 767px) {
  .plone-toolbar-left-expanded .header.fixed-top {
    margin-left: 0px;
  }
}

.header .icon-user::before,
.header .icon-search::before {
  content: none;
}

.header .user-actions {
  position: relative;
}

.header .user-actions .dropdown-menu {
  min-width: 100%;
  z-index: 1;
  display: block;
  transition: transform .5s;
  transform: translateY(-100%);
  box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0);
}

.header .user-actions .dropdown-menu::before {
  content: " ";
  position: absolute;
  bottom: 100%;
  width: 120%;
  height: 100%;
  background-color: #FFFFFF;
  transition: .5s;
  transform: translateY(100%);
  margin-left: -10%;
}

.header .user-actions .dropdown-menu a {
  text-align: center;
  padding: 10px;
  white-space: normal;
}

.header .user-actions.open .dropdown-menu {
  transform: translateY(0%);
  box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0.08);
}

.header .user-actions.open .dropdown-menu::before {
  transform: translateY(0%);
}

.header #user-name {
  margin: 0 15px;
  line-height: 2.5rem;
  z-index: 2;
  position: relative;
}

.header #user-name .chevron-down {
  display: inline-block;
  width: 1rem;
  transition-duration: 0.3s;
}

.header #user-name[aria-expanded="true"] .chevron-down {
  transform: rotate(180deg);
}

.header #user-name, .header #user-name:hover, .header #user-name:active,
.header #user-name a:visited {
  text-decoration: none;
}

.header #user-name .username {
  margin: 10px 15px 10px 5px;
  font-size: 12px;
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-weight: normal;
}

.header #user-name .username .caret {
  display: none;
}

.header #user-name svg g {
  transform: scale(1.3) translateX(-0.2) translateY(-O 0.5);
}

.header .logo-header {
  flex: 1;
}

.header .dropdown-menu {
  right: 0;
  margin: 0;
  font-size: 12px;
}

.header .dropdown-menu a {
  padding: 18px 10px 10px 10px;
  text-align: center;
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-size: 12px;
  letter-spacing: 0.05rem;
}

.header .dropdown-menu a:hover, .header .dropdown-menu a:focus {
  background-color: #000000;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  .header {
    padding: 15px;
    min-height: auto;
  }
  .header #user-name {
    padding-right: 15px;
  }
  .header #user-name span *:not(.caret) {
    display: none;
  }
  .header #user-name .desktop {
    display: none;
  }
  .header .user-actions {
    margin-right: 0;
  }
  .header > div > div {
    padding: 0;
  }
  .header .dropdown-menu {
    left: auto;
    right: 0;
  }
}

.header a:hover,
.header a:focus {
  color: #000;
}

.row::before,
.row::after {
  display: none;
}

.navbar-brand {
  margin-bottom: 5px;
  margin-right: 0;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-nav li.index_html {
  display: none;
}

.template-contact-info .header,
.template-login .header,
.template-login-help .header {
  min-height: auto;
  padding: 2rem 1rem;
}

.navbar {
  padding: 0;
}

.navbar-nav .nav-item .nav-link {
  padding: 20px 0 16px 0;
}

.navbar-nav .nav-item .nav-link + .dropdown-menu {
  max-height: 50vh;
  overflow: auto;
}

.nav-item {
  font-size: 12px;
  margin: 0 2em;
}

.nav-item .nav-link {
  border-bottom: 5px solid transparent;
  height: 100%;
  font-family: "ABChanel Corpo_Regular", sans-serif;
}

.nav-item .nav-link:focus {
  color: #000;
}

.nav-item:hover .nav-link, .nav-item.active .nav-link, .nav-item.current .nav-link {
  border-bottom: 5px solid #000;
  color: #000;
}

.dropdown-menu {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  padding: 0;
  border: none;
  border-radius: 0;
  margin-left: 1.5rem;
  margin-top: 0;
  box-shadow: 0 12px 10px 0px rgba(0, 0, 0, 0.08);
  min-width: 13rem;
}

.dropdown-item {
  font-size: 12px;
  padding: 1rem 1.8rem !important;
  text-align: left !important;
  font-weight: normal;
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  white-space: normal;
}

.dropdown-item:hover {
  background-color: #000;
  color: #fff;
}

.template-big_thumbnails #content,
.template-big_thumbnails #content-alert {
  margin-left: 0;
  transition: all 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
}

.template-big_thumbnails.portlet-opened #content,
.template-big_thumbnails.portlet-opened #content-alert {
  width: 100%;
}

.template-small_thumbnails #content-alert,
.template-small_thumbnails #content,
.template-small_thumbnails #content-core,
.template-small_thumbnails #breadcrumb,
.template-facetednavigation_view #content-alert,
.template-facetednavigation_view #content,
.template-facetednavigation_view #content-core,
.template-facetednavigation_view #breadcrumb,
.template-video_view #content-alert,
.template-video_view #content,
.template-video_view #content-core,
.template-video_view #breadcrumb,
.template-flowplayer #content-alert,
.template-flowplayer #content,
.template-flowplayer #content-core,
.template-flowplayer #breadcrumb,
.template-listing_view #content-alert,
.template-listing_view #content,
.template-listing_view #content-core,
.template-listing_view #breadcrumb,
.template-folder #content-alert,
.template-folder #content,
.template-folder #content-core,
.template-folder #breadcrumb {
  /* 0.5 second transition effect to slide in the sidenav */
}

@media (max-width: 767px) {
  .template-small_thumbnails #content-alert,
  .template-small_thumbnails #content,
  .template-small_thumbnails #content-core,
  .template-small_thumbnails #breadcrumb,
  .template-facetednavigation_view #content-alert,
  .template-facetednavigation_view #content,
  .template-facetednavigation_view #content-core,
  .template-facetednavigation_view #breadcrumb,
  .template-video_view #content-alert,
  .template-video_view #content,
  .template-video_view #content-core,
  .template-video_view #breadcrumb,
  .template-flowplayer #content-alert,
  .template-flowplayer #content,
  .template-flowplayer #content-core,
  .template-flowplayer #breadcrumb,
  .template-listing_view #content-alert,
  .template-listing_view #content,
  .template-listing_view #content-core,
  .template-listing_view #breadcrumb,
  .template-folder #content-alert,
  .template-folder #content,
  .template-folder #content-core,
  .template-folder #breadcrumb {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0 15px;
    line-height: 16px;
  }
}

.template-small_thumbnails.portlet-opened #content,
.template-small_thumbnails.portlet-opened #content-alert,
.template-small_thumbnails.portlet-opened #content-core,
.template-small_thumbnails.portlet-opened #breadcrumb,
.template-facetednavigation_view.portlet-opened #content,
.template-facetednavigation_view.portlet-opened #content-alert,
.template-facetednavigation_view.portlet-opened #content-core,
.template-facetednavigation_view.portlet-opened #breadcrumb,
.template-video_view.portlet-opened #content,
.template-video_view.portlet-opened #content-alert,
.template-video_view.portlet-opened #content-core,
.template-video_view.portlet-opened #breadcrumb,
.template-flowplayer.portlet-opened #content,
.template-flowplayer.portlet-opened #content-alert,
.template-flowplayer.portlet-opened #content-core,
.template-flowplayer.portlet-opened #breadcrumb,
.template-listing_view.portlet-opened #content,
.template-listing_view.portlet-opened #content-alert,
.template-listing_view.portlet-opened #content-core,
.template-listing_view.portlet-opened #breadcrumb,
.template-folder.portlet-opened #content,
.template-folder.portlet-opened #content-alert,
.template-folder.portlet-opened #content-core,
.template-folder.portlet-opened #breadcrumb {
  width: 100%;
}

.template-small_thumbnails.portlet-opened .card-list .card,
.template-facetednavigation_view.portlet-opened .card-list .card,
.template-video_view.portlet-opened .card-list .card,
.template-flowplayer.portlet-opened .card-list .card,
.template-listing_view.portlet-opened .card-list .card,
.template-folder.portlet-opened .card-list .card {
  border: none;
  padding-left: 5px;
  padding-right: 5px;
}

.breadcrumb {
  padding-top: 24px;
}

.template-facetednavigation_view #breadcrumb.container-fluid, .template-facetednavigation_view #breadcrumb.container-sm, .template-facetednavigation_view #breadcrumb.container-md, .template-facetednavigation_view #breadcrumb.container-lg, .template-facetednavigation_view #breadcrumb.container-xl,
.template-twofold_view #breadcrumb.container-fluid,
.template-twofold_view #breadcrumb.container-sm,
.template-twofold_view #breadcrumb.container-md,
.template-twofold_view #breadcrumb.container-lg,
.template-twofold_view #breadcrumb.container-xl,
.template-small_thumbnails #breadcrumb.container-fluid,
.template-small_thumbnails #breadcrumb.container-sm,
.template-small_thumbnails #breadcrumb.container-md,
.template-small_thumbnails #breadcrumb.container-lg,
.template-small_thumbnails #breadcrumb.container-xl {
  padding-left: 25px;
}

.template-facetednavigation_view.portlet-opened #breadcrumb.container-fluid, .template-facetednavigation_view.portlet-opened #breadcrumb.container-sm, .template-facetednavigation_view.portlet-opened #breadcrumb.container-md, .template-facetednavigation_view.portlet-opened #breadcrumb.container-lg, .template-facetednavigation_view.portlet-opened #breadcrumb.container-xl,
.template-twofold_view.portlet-opened #breadcrumb.container-fluid,
.template-twofold_view.portlet-opened #breadcrumb.container-sm,
.template-twofold_view.portlet-opened #breadcrumb.container-md,
.template-twofold_view.portlet-opened #breadcrumb.container-lg,
.template-twofold_view.portlet-opened #breadcrumb.container-xl,
.template-small_thumbnails.portlet-opened #breadcrumb.container-fluid,
.template-small_thumbnails.portlet-opened #breadcrumb.container-sm,
.template-small_thumbnails.portlet-opened #breadcrumb.container-md,
.template-small_thumbnails.portlet-opened #breadcrumb.container-lg,
.template-small_thumbnails.portlet-opened #breadcrumb.container-xl {
  padding-left: 25px;
}

.template-facetednavigation_view.portlet-opened .card-list .card,
.template-twofold_view.portlet-opened .card-list .card,
.template-small_thumbnails.portlet-opened .card-list .card {
  border: none;
  padding-left: 15px;
  padding-right: 15px;
}

.template-big_thumbnails #content-alert,
.template-big_thumbnails #content,
.template-big_thumbnails #breadcrumb {
  width: 100%;
  transition: all 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
  margin-left: 0;
  margin-right: 0;
}

.template-big_thumbnails.portlet-opened #content,
.template-big_thumbnails.portlet-opened #content-alert,
.template-big_thumbnails.portlet-opened #breadcrumb {
  width: 100%;
  margin-right: 0px;
}

.template-big_thumbnails.portlet-opened .card-list .card {
  border: none;
  padding-left: 5px;
  padding-right: 5px;
}

#portlet-toggler,
#setting-toggler {
  appearance: none;
  border: 0;
  background-color: transparent;
  padding: 0;
  padding-bottom: 29px;
}

#portlet-toggler svg,
#setting-toggler svg {
  transition: 0.5s;
}

#portlet-toggler:focus, #portlet-toggler:hover,
#setting-toggler:focus,
#setting-toggler:hover {
  box-shadow: none;
  outline: none;
}

.portlet-opened #portlet-toggler svg, .portlet-opened
#setting-toggler svg {
  transform: rotate(90deg);
  transition: 0.5s;
}

#portlet-toggler svg {
  stroke: black;
}

.template-facetednavigation_view #portlet-toggler,
.template-facetednavigation_view #setting-toggler {
  box-shadow: none;
}

.template-facetednavigation_view #portlet-toggler.active svg {
  transform: rotate(90deg);
  stroke: black;
}

.template-facetednavigation_view #portlet-toggler:not(.active) svg {
  stroke: #C5C5C5;
}

.template-facetednavigation_view #setting-toggler.active svg {
  transform: rotate(180deg);
  fill: black;
}

.template-facetednavigation_view #setting-toggler:not(.active) svg {
  fill: #C5C5C5;
}

@media (max-width: 767px) {
  .template-facetednavigation_view #setting-toggler {
    padding-bottom: 5px;
  }
}

.burger-portlet-toggler {
  z-index: 2;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F8F8F8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D8D8D8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D8D8D8;
}

.sidenav {
  position: relative;
  width: 0;
  z-index: 1;
  background-color: #fff;
  overflow-x: hidden;
  transition-duration: 0.5s;
  transition-delay: 0.5s;
}

.sidenav .collapse.in .collapse:not(.in) {
  display: block;
}

.sidenav .nav,
.sidenav #portal-column-one {
  padding: 2.5rem 1.5rem 0 2.5rem;
  opacity: 0;
  width: 100%;
  transition-duration: 0.5s;
  transition-delay: 0;
  position: relative;
}

.sidenav #portal-column-one {
  padding: 1rem 1.5rem 0 2.5rem;
}

.sidenav #portal-column-one .portletWrapper {
  display: none;
}

.sidenav.active {
  left: 0;
  width: 20vw;
  transition-duration: 0.5s;
  transition-delay: 0s;
}

.sidenav.active .nav,
.sidenav.active #portal-column-one {
  opacity: 1;
  transition-duration: 0.5s;
  transition-delay: 0.5s;
}

.sidenav.active .nav .portletWrapper,
.sidenav.active #portal-column-one .portletWrapper {
  transition-delay: 0.5s;
  display: inline-block;
}

.sidenav:not(.active) .nav-item {
  display: none;
}

.sidenav .nav-dropdown-items {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  padding: 0;
}

.sidenav .nav-dropdown-items .nav-item {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  padding-bottom: 0.5em;
}

.sidenav .nav-item {
  font-family: 'Helvetica Neue LT Std 65 Medium';
  font-size: 14px;
  text-align: left;
  padding: 0 0 1rem;
  margin: 0;
}

.sidenav .nav-item a {
  width: 100%;
  display: block;
  white-space: normal;
  position: relative;
}

.sidenav .nav-item a:hover {
  transition: .5s;
  color: #484848;
  text-decoration: none;
}

.sidenav .nav-item a.dropdown-toggle {
  padding-right: 2rem;
}

.sidenav .nav-item a.dropdown-toggle::after {
  background: url("../img/dropdown-caret.svg") no-repeat center center;
  border: none;
  width: 15px;
  height: 15px;
  transition: 0.3s;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.sidenav .nav-item a.dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

@media (max-width: 767px) {
  .sidenav {
    width: 100%;
    height: 0;
    transition-delay: 0s;
    transition-duration: 0s;
    opacity: 1;
  }
  .sidenav.active {
    width: 100%;
    height: auto;
    transition-delay: 0s;
    transition-duration: 0s;
  }
  .sidenav .nav {
    position: relative;
    opacity: 1;
    transition-delay: 0s;
    transition-duration: 0s;
    padding: 1.5rem 0;
  }
  .sidenav .nav .nav-item {
    padding: 0;
  }
  .sidenav .nav .nav-item a {
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0);
  }
  .sidenav .nav .nav-item a:hover, .sidenav .nav .nav-item a:active, .sidenav .nav .nav-item a:focus {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.icon-search:before {
  display: none !important;
}

.collapse {
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapse {
    transition: none;
  }
}

.collapse:not(.in) {
  display: none;
  height: auto;
}

.collapsing {
  position: relative;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

@media (max-width: 767px) {
  #portlet-toggler {
    padding: 5px 0;
  }
}

.entry-list a {
  display: block;
  text-decoration: none;
  color: inherit;
}

.image-block {
  width: 33.33%;
  display: inline-flex;
  height: 420px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.image-block .content {
  margin: 2rem;
  background-color: #fff;
  text-align: left;
  padding: 0.5rem 0.5rem 0;
  min-width: 10%;
  max-width: 50%;
  border: 1px solid #E5E5E5;
  transition: .5s;
  position: relative;
  box-shadow: 3px 5px 5px 0 rgba(0, 0, 0, 0.1);
  transition-property: height min-width;
  transition-duration: 0.5s;
}

.image-block .content .description {
  height: 0;
  width: 0;
  overflow: hidden;
  transition-property: height width;
  transition-duration: 0.8s;
  font-size: 12px;
}

.image-block .content .description::before {
  content: " ";
  height: 1rem;
  display: block;
}

.image-block .content h3 {
  font-size: 12px;
  cursor: pointer;
  font-family: "ABChanel Corpo_Regular", sans-serif;
}

.image-block .content p {
  font-size: 12px;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
}

.image-block:hover .content {
  min-width: 40%;
  max-width: 50%;
  height: auto;
  transition-property: height min-width;
  transition-duration: 0.5s;
}

.image-block:hover .description:not(:empty) {
  width: auto;
  height: 120px;
  transition-property: height width;
  transition-duration: 0.5s;
}

.image-block:hover .description:not(:empty)::after {
  content: " ";
  display: block;
  width: 100%;
  height: 40px;
  background: linear-gradient(transparent, white 50%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-list {
  justify-content: flex-start !important;
}

.card-list.row {
  margin-left: -10px;
  margin-right: -10px;
}

.card-list .card {
  border: none;
}

.card-list .card-body {
  padding: 16px 2.5rem 12px 12px;
  text-align: left;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  position: relative;
}

.card-list .pastille-validation {
  height: 24px;
  width: 24px;
  background-color: #CCCCCC;
  display: block;
  position: absolute;
  right: 0.5rem;
  top: 8px;
  border-radius: 50%;
}

.card-list .pastille-validation.validated {
  background-color: #2B5A36;
}

.card-list .pastille-validation.rejected {
  background-color: #8E000D;
}

.card h3 {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
}

.card p {
  font-size: 12px;
  margin-bottom: 0;
}

.card p:not(:empty) {
  margin-top: 0.5em;
}

.card-shadow {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 0 !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.card-shadow .card-link {
  margin-bottom: auto;
}

.card-shadow > a:not(.card-link),
.card-shadow > button .card-button-image {
  width: 100%;
  padding-bottom: 60% !important;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.card-shadow > button, .card-shadow > button:hover, .card-shadow > button:active, .card-shadow > button:focus {
  width: 100%;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.card .card-shadow {
  height: 100%;
  margin-bottom: 30px;
}

.card-shadow .card-img-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card-shadow .card-img-content::after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.card-shadow .card-img-content .card-img-top {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  border-radius: 0;
}

.owl-carousel .owl-stage {
  display: flex;
}

.owl-carousel .owl-stage .owl-item {
  display: inline-block;
  min-height: 100%;
  float: none;
}

.owl-carousel .owl-stage .owl-item .card {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.owl-carousel .owl-stage .owl-item .card-img-block {
  width: 100%;
  padding-bottom: 60%;
  position: relative;
}

.owl-carousel .owl-stage .owl-item .card-img-block .card-img-top {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 0;
}

.portaltype-collection [class*=contenttype-]::before {
  display: none;
}

.portaltype-collection img[class*=thumb-] {
  border: 1px solid #e5e5e5;
}

.discussion {
  padding: 2rem 0;
}

.discussion .comment {
  clear: both;
  padding-left: 0;
}

.discussion .comment:first-of-type {
  margin-top: 0;
}

.discussion .documentByLine {
  text-align: left;
  color: #000;
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-size: 16px;
}

.discussion .commentDate {
  float: none !important;
  display: block;
  color: #000;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 0.2rem;
}

.discussion .commentBody {
  background-color: #F6F6F6;
  border: none;
  margin: 1rem 0rem 1rem 2rem;
  text-align: left;
  color: #000;
  font-size: 14px;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
}

.discussion .reply-to-comment-button {
  display: none;
}

.commentActions .destructive {
  width: 18px;
  height: 18px;
  text-indent: -2000em;
  background-color: transparent;
  border: none;
  padding: 0;
  background-size: cover;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDU5IDQ1OSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDU5IDQ1OTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJkZWxldGUiPgoJCTxwYXRoIGQ9Ik03Ni41LDQwOGMwLDI4LjA1LDIyLjk1LDUxLDUxLDUxaDIwNGMyOC4wNSwwLDUxLTIyLjk1LDUxLTUxVjEwMmgtMzA2VjQwOHogTTQwOCwyNS41aC04OS4yNUwyOTMuMjUsMGgtMTI3LjVsLTI1LjUsMjUuNSAgICBINTF2NTFoMzU3VjI1LjV6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.commentActions a.commentactionsform {
  display: inline-block;
  width: 18px;
  height: 18px;
  text-indent: -2000em;
  background-color: transparent;
  border: none;
  padding: 0;
  background-size: cover;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTI4Ljg5OSA1MjguODk5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MjguODk5IDUyOC44OTk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMzI4Ljg4Myw4OS4xMjVsMTA3LjU5LDEwNy41ODlsLTI3Mi4zNCwyNzIuMzRMNTYuNjA0LDM2MS40NjVMMzI4Ljg4Myw4OS4xMjV6IE01MTguMTEzLDYzLjE3N2wtNDcuOTgxLTQ3Ljk4MSAgIGMtMTguNTQzLTE4LjU0My00OC42NTMtMTguNTQzLTY3LjI1OSwwbC00NS45NjEsNDUuOTYxbDEwNy41OSwxMDcuNTlsNTMuNjExLTUzLjYxMSAgIEM1MzIuNDk1LDEwMC43NTMsNTMyLjQ5NSw3Ny41NTksNTE4LjExMyw2My4xNzd6IE0wLjMsNTEyLjY5Yy0xLjk1OCw4LjgxMiw1Ljk5OCwxNi43MDgsMTQuODExLDE0LjU2NWwxMTkuODkxLTI5LjA2OSAgIEwyNy40NzMsMzkwLjU5N0wwLjMsNTEyLjY5eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

.context {
  background-color: #000;
  border-color: transparent;
}

.context.reply-to-comment-button {
  float: left;
  margin-bottom: 30px;
}

#commenting,
.discussion {
  padding-right: 5px;
}

#commenting legend,
.discussion legend {
  text-align: left;
}

#commenting .documentByLine a,
.discussion .documentByLine a {
  font-size: 16px;
}

#commenting .commentImage,
.discussion .commentImage {
  display: none;
}

.form-widgets-comment-text {
  display: none;
}

#commenting .discussion, #commenting #commenting.reply {
  padding-left: 1.2%;
  padding-right: 1.05%;
}

#commenting legend {
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-size: 1rem;
  border-bottom: none;
  margin-bottom: 0;
  padding: 2rem 0 0 0;
}

#commenting #form-widgets-comment-text {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

#commenting p {
  text-align: left;
}

#commenting .formControls {
  background: transparent;
  border-top: none;
  padding: 0;
  box-shadow: none;
  text-align: left;
}

#commenting .formControls input {
  border: none;
  border-radius: 0;
}

#commenting .formControls #form-buttons-comment {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  padding: 10px 20px;
  font-weight: normal;
}

#commenting #formfield-form-widgets-comment-text label {
  display: none;
}

#commenting fieldset > p {
  display: none;
}

#commenting-flowplayer {
  position: sticky;
  right: 100%;
}

.portaltype-file #content #commenting {
  padding-right: 0;
}

.portaltype-file #content > #commenting {
  width: 75%;
  margin: 0 auto;
}

#edit-zone {
  z-index: 10000;
  position: relative;
}

.section-fragrance #video-metadata-container .validation {
  display: none;
}

.section-fragrance #video-comments-container {
  display: none;
}

#content #configure-conditions,
#content #configure-actions {
  width: auto;
  padding: 0;
  float: none;
}

@media (max-width: 767px) {
  #video-metadata-container {
    padding: 10px 0;
  }
  #video-comments-container {
    padding: 0;
  }
}

#content-video {
  flex: 1;
  max-width: 100%;
}

#content-video.aside-right {
  max-width: calc(100% - 20vw);
}

#video-container {
  padding-left: 0;
}

#video-container hr {
  border-color: transparent;
}

#video-aside {
  display: block;
  position: relative;
}

#video-metadata-container {
  display: flex;
}

#video-metadata-container #video-metadata-content {
  flex: 1;
  text-align: left;
  margin-right: 1rem;
}

#video-metadata-container #form-video-state label {
  max-width: none;
}

.side-rich-description-video,
.main-rich-description-video {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
  text-align: left;
}

.main-rich-description-video {
  margin-top: 2rem;
}

.video-js {
  background-color: transparent !important;
  border: 1px solid #cfcfcf !important;
}

.video-js .vjs-control:focus {
  outline: none;
  box-shadow: none;
}

.video-js .vjs-current-time {
  display: block !important;
}

.video-js .vjs-time-divider {
  display: block !important;
}

.video-js .vjs-duration {
  display: block !important;
}

.video-js .vjs-duration-display {
  display: block !important;
}

.video-js .vjs-tech {
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  margin: -1px;
}

.video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3 {
  overflow: hidden;
}

.video-js.vjs-fullscreen {
  border: none !important;
}

.vjs-poster {
  background-color: #fff !important;
  background-size: cover !important;
}

.vjs-text-track-display div {
  margin: 0 !important;
}

#pos-download,
#internal-use {
  margin-bottom: 1rem;
}

.campaign-information,
.asset-information {
  margin-top: 2rem;
  text-align: left;
}

.campaign-information h3,
.asset-information h3 {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.campaign-information dl,
.asset-information dl {
  margin-bottom: 15px;
  display: block;
}

.campaign-information dd,
.campaign-information dt,
.asset-information dd,
.asset-information dt {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
}

.campaign-information dt,
.asset-information dt {
  width: 130px;
  display: inline-block;
}

.campaign-information dd,
.asset-information dd {
  width: calc(100% - 140px);
  display: inline-block;
}

.keywords {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
  text-align: left;
}

.keywords ul.list-diese li {
  margin-right: 10px;
}

.keywords ul.list-diese li:before {
  content: "#";
}

.owl-carousel .owl-stage {
  padding-bottom: 10px;
}

.owl-carousel .owl-stage .owl-item {
  padding: 10px;
  transition: all 0.2s;
}

.owl-carousel .owl-stage .owl-item:first-child:last-child {
  display: none;
}

.owl-carousel .owl-stage .owl-item:hover {
  transform: scale(1.02);
}

.owl-carousel .owl-stage .owl-item:hover .card {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  opacity: .8;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  font-size: 0;
}

.owl-carousel .owl-nav .owl-prev::before,
.owl-carousel .owl-nav .owl-next::before {
  content: "";
  background: url(../img/prev.svg) no-repeat;
  background-size: 20px;
  background-position: center;
  display: block;
  width: 10px;
  height: 100%;
}

.owl-carousel .owl-nav .owl-next::before {
  background-image: url(../img/next.svg);
}

.owl-theme .owl-nav .disabled {
  opacity: 1 !important;
}

.owl-theme .owl-nav .disabled::before {
  opacity: 0.3 !important;
}

.video-thumbnail.card {
  border-radius: 0;
  cursor: pointer;
  height: 100%;
  border: none;
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.video-thumbnail.card .title {
  padding: 10px;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 12px;
  text-align: left;
}

.video-thumbnail.card .card-img-block {
  border: 1px solid #EBEBEB;
}

#video-aside {
  display: flex;
  flex-direction: column;
  height: auto;
}

#video-aside .section.clearfix,
#video-aside .download-button {
  margin-top: auto;
}

.template-video_view #video-richetext {
  padding: 0;
}

.template-video_view #spinner-center {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 100;
}

.template-video_view #spinner-center > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.template-video_view #spinner-center > div .spinner-border {
  border: .25em solid white;
  border-right-color: transparent;
  width: 3rem;
  height: 3rem;
}

@media (max-width: 991px) {
  #video-aside {
    height: auto;
    margin-top: 2rem;
  }
}

.section-front-page #breadcrumb {
  display: none;
}

.documentFirstHeading {
  margin-top: 2em;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
}

.plone-toolbar-default #content {
  text-align: left;
  margin: 0 5% 0 5%;
}

.plone-toolbar-default #content .documentFirstHeading {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.plone-toolbar-default #content .documentByLine {
  padding-top: 10px;
}

.plone-toolbar-default #content #content-core {
  text-align: left;
}

.plone-toolbar-default.template-big_thumbnails #content {
  text-align: left;
  margin: 0 0 0 0;
}

.template-taxonomy-settings #content,
.template-taxonomy-edit-data #content {
  text-align: left;
}

.template-taxonomy-settings #content label,
.template-taxonomy-edit-data #content label {
  vertical-align: baseline;
}

.template-taxonomy-settings #content .taxonomy-tree,
.template-taxonomy-edit-data #content .taxonomy-tree {
  border: 1px groove;
  padding: 12px;
}

.template-taxonomy-settings #content .react-pencil,
.template-taxonomy-edit-data #content .react-pencil {
  min-width: 47%;
}

.template-taxonomy-settings #content .tree-view_children,
.template-taxonomy-edit-data #content .tree-view_children {
  margin-left: 0;
}

.template-taxonomy-settings #content .info,
.template-taxonomy-edit-data #content .info {
  height: auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.template-taxonomy-settings #content .info div:first-child,
.template-taxonomy-edit-data #content .info div:first-child {
  margin-right: auto;
}

#content #content-core #form {
  text-align: left;
  margin-left: 12px;
}

.template-user_experience #content .header-content {
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-bottom: none;
  margin-bottom: 0;
  flex-direction: column;
  padding-bottom: 20px;
}

.template-user_experience #content .header-content h1 {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  margin-top: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #cccccc;
}

.template-user_experience #content .header-content .button {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  background-color: #000;
  color: #FFFFFF;
  padding: 10px 20px;
  align-self: center;
  margin-top: 12px;
}

.template-user_experience #content .has-table table {
  color: #000;
}

.template-user_experience #content .has-table table td {
  padding: 18px 20px 10px 20px;
}

.template-user_experience #content .has-table tr th {
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  font-weight: normal;
  padding: 14px 8px 8px 8px;
}

.template-user_experience #content .has-table tr td:first-child a {
  text-transform: uppercase;
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  font-size: 16px;
}

.template-user_experience #content .has-table tr td:first-child a::before {
  display: none;
}

.template-user_experience #content .has-table tr td:first-child p {
  color: #000;
  margin-bottom: 0;
}

.template-add-trouble-ticket #form {
  margin: 20px 0 0 0 !important;
}

.template-add-trouble-ticket label {
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-weight: normal;
}

.template-add-trouble-ticket input,
.template-add-trouble-ticket textarea {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.template-add-trouble-ticket .formControls {
  padding: 0;
}

.template-add-trouble-ticket #form-buttons-send,
.template-add-trouble-ticket #form-buttons-cancel {
  border: 1px solid transparent;
  border-radius: 0;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  box-shadow: none;
  text-shadow: none;
  padding: 10px 20px;
  font-weight: normal;
}

.template-add-trouble-ticket #form-buttons-send {
  background-color: #000;
  color: #FFFFFF;
  border: 1px solid #000;
}

.template-add-trouble-ticket #form-buttons-cancel {
  border: 1px solid #CCC;
}

.template-add-trouble-ticket .mce-top-part {
  display: none;
}

.portaltype-troubleticket #content-block {
  justify-content: center;
}

@media (min-width: 992px) {
  .portaltype-troubleticket #content-block #content {
    max-width: 70% !important;
  }
}

.portaltype-troubleticket h1 {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 14px;
  margin-top: 5em;
}

.portaltype-troubleticket #folder-listing,
.portaltype-troubleticket #viewlet-below-content-title,
.portaltype-troubleticket #form-groups-settings,
.portaltype-troubleticket #formfield-form-widgets-trouble_description label .formHelp {
  display: none;
}

.portaltype-troubleticket #commenting-troubleticket {
  margin-top: 20px;
}

.portaltype-troubleticket .documentDescription {
  text-align: left;
  margin-top: 15px;
}

.portaltype-troubleticket #formfield-form-widgets-trouble_date,
.portaltype-troubleticket #formfield-form-widgets-email {
  display: flex;
  justify-content: center;
}

.portaltype-troubleticket #formfield-form-widgets-trouble_date label,
.portaltype-troubleticket #formfield-form-widgets-email label {
  margin-right: 5px;
}

.portaltype-troubleticket.template-edit .mce-top-part {
  display: none;
}

.template-flowplayer #content-core,
.template-flowplayer #content,
.template-big_thumbnails #content-core,
.template-big_thumbnails #content,
.template-small_thumbnails #content-core,
.template-small_thumbnails #content,
.template-twofold_view #content-core,
.template-twofold_view #content,
.template-facetednavigation_view #content-core,
.template-facetednavigation_view #content,
.template-video_view #content-core,
.template-video_view #content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.template-flowplayer #content-core #thumbnails .card,
.template-flowplayer #content #thumbnails .card,
.template-big_thumbnails #content-core #thumbnails .card,
.template-big_thumbnails #content #thumbnails .card,
.template-small_thumbnails #content-core #thumbnails .card,
.template-small_thumbnails #content #thumbnails .card,
.template-twofold_view #content-core #thumbnails .card,
.template-twofold_view #content #thumbnails .card,
.template-facetednavigation_view #content-core #thumbnails .card,
.template-facetednavigation_view #content #thumbnails .card,
.template-video_view #content-core #thumbnails .card,
.template-video_view #content #thumbnails .card {
  padding: 0;
}

@media (min-width: 1600px) {
  .template-flowplayer #content-core #thumbnails .card,
  .template-flowplayer #content #thumbnails .card,
  .template-big_thumbnails #content-core #thumbnails .card,
  .template-big_thumbnails #content #thumbnails .card,
  .template-small_thumbnails #content-core #thumbnails .card,
  .template-small_thumbnails #content #thumbnails .card,
  .template-twofold_view #content-core #thumbnails .card,
  .template-twofold_view #content #thumbnails .card,
  .template-facetednavigation_view #content-core #thumbnails .card,
  .template-facetednavigation_view #content #thumbnails .card,
  .template-video_view #content-core #thumbnails .card,
  .template-video_view #content #thumbnails .card {
    flex: 0 0 19%;
    max-width: 19%;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .template-flowplayer #content-core #thumbnails .card,
  .template-flowplayer #content #thumbnails .card,
  .template-big_thumbnails #content-core #thumbnails .card,
  .template-big_thumbnails #content #thumbnails .card,
  .template-small_thumbnails #content-core #thumbnails .card,
  .template-small_thumbnails #content #thumbnails .card,
  .template-twofold_view #content-core #thumbnails .card,
  .template-twofold_view #content #thumbnails .card,
  .template-facetednavigation_view #content-core #thumbnails .card,
  .template-facetednavigation_view #content #thumbnails .card,
  .template-video_view #content-core #thumbnails .card,
  .template-video_view #content #thumbnails .card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.campaign-information {
  margin-top: 0;
}

.campaign-information h3 {
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  position: relative;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.campaign-information h3::before {
  content: "";
  background: url(../img/info.svg) no-repeat;
  background-size: 20px;
  background-position: center;
  display: inline-flex;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 20px;
  margin-right: 0.5rem;
  margin-top: -0.2rem;
}

.campaign-information dt {
  font-weight: bold;
  vertical-align: top;
}

.campaign-information-button {
  display: flex;
  align-items: center;
  margin-bottom: auto;
  opacity: 1;
  padding: 0.2rem 1rem;
  margin-left: 1rem;
  border: 1px solid transparent;
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  font-size: 12px;
  margin-bottom: 1rem;
  justify-content: center;
}

.campaign-information-button:hover, .campaign-information-button:focus, .campaign-information-button:active {
  opacity: 0.7;
  text-decoration: none;
}

.campaign-information-button .campaign-information-info {
  position: relative;
  padding: 0 1rem;
}

.campaign-information-button .campaign-information-info::before {
  content: " ";
  display: inline-block;
  border: 2px solid #000;
  border-radius: 50%;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
}

.campaign-information-button .campaign-information-text {
  text-align: left;
  padding-left: 0.5rem;
}

.downloads-button .download-button + .modal .modal-body ul li a {
  word-break: break-all;
}

.template-big_thumbnails main #main-content {
  width: 100%;
}

.template-big_thumbnails:not(.portlet-opened) #breadcrumb {
  margin-left: 1rem;
  transition-duration: 0.3;
  transition-delay: 0.5s;
}

.template-big_thumbnails.portlet-opened #breadcrumb {
  padding-left: 0;
  margin-left: 0;
  transition-duration: 0.3;
  transition-delay: 0;
}

.template-big_thumbnails #thumbnails {
  width: 100%;
}

.template-big_thumbnails #thumbnails .entry-list.row {
  margin-left: 0;
  margin-right: 0;
  padding-left: 6px;
}

.template-big_thumbnails #thumbnails .folder-content {
  padding: 0px 6px 6px 0;
}

.template-big_thumbnails #thumbnails .folder-content .folder {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.4);
}

.template-small_thumbnails .modal-backdrop.show,
.template-big_thumbnails .modal-backdrop.show {
  opacity: 0;
}

.template-small_thumbnails #commenting,
.template-big_thumbnails #commenting {
  width: 100%;
}

.template-facetednavigation_view #content-core #thumbnails .card,
.template-twofold_view #content-core #thumbnails .card,
.template-small_thumbnails #content #thumbnails .card {
  flex: 0 0 18%;
  max-width: 18%;
  margin: 1%;
  transition: all .2s;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .template-facetednavigation_view #content-core #thumbnails .card,
  .template-twofold_view #content-core #thumbnails .card,
  .template-small_thumbnails #content #thumbnails .card {
    flex: 0 0 23%;
    max-width: 23%;
  }
}

@media (max-width: 992px) {
  .template-facetednavigation_view #content-core #thumbnails .card,
  .template-twofold_view #content-core #thumbnails .card,
  .template-small_thumbnails #content #thumbnails .card {
    flex: 0 0 31%;
    max-width: 31%;
  }
}

@media (max-width: 768px) {
  .template-facetednavigation_view #content-core #thumbnails .card,
  .template-twofold_view #content-core #thumbnails .card,
  .template-small_thumbnails #content #thumbnails .card {
    flex: 0 0 48%;
    max-width: 48%;
  }
}

@media (max-width: 480px) {
  .template-facetednavigation_view #content-core #thumbnails .card,
  .template-twofold_view #content-core #thumbnails .card,
  .template-small_thumbnails #content #thumbnails .card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.template-facetednavigation_view #content-core #thumbnails .card:hover, .template-facetednavigation_view #content-core #thumbnails .card:active,
.template-twofold_view #content-core #thumbnails .card:hover,
.template-twofold_view #content-core #thumbnails .card:active,
.template-small_thumbnails #content #thumbnails .card:hover,
.template-small_thumbnails #content #thumbnails .card:active {
  transform: scale(1.02);
  z-index: 1;
  opacity: 0.8;
}

.template-flowplayer #video-aside .section.clearfix {
  padding-bottom: 11rem;
}

.template-flowplayer .modal-dialog {
  max-width: 80vw;
}

.template-flowplayer .modal-dialog .modal-content .close:before {
  font-size: 37px;
}

.template-flowplayer .modal-dialog .modal-body .modal-description {
  margin: 40px 0 0 0;
}

.template-flowplayer .modal-dialog .modal-body .modal-title {
  font-family: "ABChanel Corpo_Semi Bold", Helvetica, sans-serif;
  font-size: 16px;
}

.template-flowplayer .modal-dialog .modal-body ul {
  margin-block: 20px 40px;
}

.template-flowplayer .modal-dialog .modal-body ul li:not(:first-child) .file-description {
  margin-top: 2.5rem;
}

.template-flowplayer .modal-dialog .modal-body ul .file-description {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  margin-bottom: 1.5rem;
  font-size: 14px;
}

.template-flowplayer .modal-dialog .modal-body ul h4 {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 14px;
}

.template-flowplayer .modal-dialog .modal-body ul a {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 12px;
  margin-bottom: 0.4rem;
  display: inline-block;
}

.template-flowplayer .modal-dialog .modal-body .modal-description + ul {
  margin: 0;
}

.template-flowplayer .modal-dialog .modal-body .download-all {
  margin-block-start: 23px;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 14px;
}

.template-flowplayer .modal-dialog .modal-body .download-all a:before {
  content: '';
  width: 30px;
  height: 30px;
  display: inline-flex;
  padding-block-end: 8px;
  background: url(../img/download_invert.svg) no-repeat;
  background-size: 30px;
  background-position: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 30px;
}

#content .pagination li > span,
#content .pagination li > a {
  background-color: transparent !important;
  border: none !important;
  color: #000000 !important;
}

#content .pagination li.active > span,
#content .pagination li.active > a {
  font-weight: bold;
}

#content .has-table {
  overflow: auto;
}

#content .has-table:first-child {
  margin-top: 73px;
}

#content .has-table td,
#content .has-table th {
  vertical-align: middle;
}

#content .has-table td {
  vertical-align: middle;
  text-align: left;
  padding: 8px 20px;
}

#content .has-table td .select2-container {
  padding: 0.3rem 1rem;
}

#content .has-table td .select2-container .select2-choice {
  border: none;
  background-color: transparent;
  background-image: none;
  display: flex;
  align-items: center;
  padding: 0;
  width: 110px;
}

#content .has-table td .select2-container .select2-choice .select2-chosen {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
}

#content .has-table td .select2-container.select2-container-active .select2-choice {
  box-shadow: none;
}

#content .has-table td .select2-container .select2-arrow {
  border: none;
  background-color: transparent;
  background-image: none;
  display: grid;
  place-content: center;
}

#content .has-table td .select2-container .select2-arrow:before {
  content: url("../img/dropdown-caret.svg");
  transform: rotate(0deg);
}

#content .has-table td .select2-container .select2-arrow b {
  display: none;
}

#content .has-table td .select2-container.select2-dropdown-open {
  outline: 1px solid #E9E9E9;
}

#content .has-table td .select2-container.select2-dropdown-open .select2-arrow:before {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

#content .has-table td.content-status {
  width: 0;
}

.template-full_view #select2-drop,
.template-tabular_view #select2-drop {
  border: 1px solid #E9E9E9;
  color: #6D6D6D;
  transform: translateY(5px);
  border-radius: 2px;
}

.template-full_view #select2-drop .select2-results,
.template-tabular_view #select2-drop .select2-results {
  margin: 0;
  padding: 0;
  text-align: left;
}

.template-full_view #select2-drop .select2-search,
.template-tabular_view #select2-drop .select2-search {
  display: none;
}

.template-full_view #select2-drop li.select2-result .select2-result-label,
.template-tabular_view #select2-drop li.select2-result .select2-result-label {
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-size: 14px;
  padding: 0.3rem 1rem;
}

.template-full_view #select2-drop li.select2-result:first-child,
.template-tabular_view #select2-drop li.select2-result:first-child {
  display: none;
}

.template-full_view #select2-drop li.select2-highlighted,
.template-tabular_view #select2-drop li.select2-highlighted {
  background-color: #EFEFEF;
  color: #6D6D6D;
}

.multi-widget .elements .element {
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.multi-widget .elements .element:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.multi-widget .elements .element div {
  margin-left: 0;
  width: 100%;
  margin-right: 0;
}

.multi-widget .elements .element .checkbox-widget,
.multi-widget .elements .element .fieldErrorBox:empty,
.multi-widget .elements .element > label,
.multi-widget .elements .element .label, .multi-widget .elements .element.error::before,
.multi-widget .elements .element .fieldErrorBox {
  display: none;
}

.multi-widget .elements .element .object-widget .object-widget-field {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 0;
}

.multi-widget .elements .element .object-widget .object-widget-field::before {
  position: absolute;
  right: 0;
}

.multi-widget .elements .element .object-widget .object-widget-field.error input {
  border-width: 2px;
}

.multi-widget .elements .element button {
  margin-bottom: 0;
}

#portal-column-two {
  padding: 0 1rem 0 2.5rem;
  width: 20vw;
}

#portal-column-two:empty {
  display: none;
}

.portletStaticText .portletHeader {
  font-size: 12px;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-weight: normal;
}

.portletStaticText .portletContent {
  padding: 0.8rem;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-weight: normal;
}

.portletStaticText .portletContent p {
  color: #000;
}

.portletStaticText.omit-border {
  border: none;
  border-radius: 0;
  box-shadow: none;
  font-weight: 700;
  margin-bottom: 30px;
}

.portletStaticText.omit-border .portletHeader {
  background-color: transparent;
  border-radius: 0;
  color: #000;
}

.portletStaticText.omit-border .portletContent {
  border-top: 1px solid #cccccc;
  padding: 0;
}

.portletStaticText.omit-border .portletContent p {
  padding: 0 0 1rem 0 !important;
}

.portletStaticText.omit-border .portletContent p:first-child {
  padding-top: 1.5rem !important;
}

.portletStaticText.omit-border .portletContent p a {
  display: flex;
  padding: 0;
  width: 100%;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  text-align: start;
}

.portletStaticText.omit-border .portletContent p a:hover, .portletStaticText.omit-border .portletContent p a:active,
.portletStaticText.omit-border .portletContent p a a:focus {
  text-decoration: none;
}

.portletStaticText.border {
  border-radius: 0;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border: none !important;
}

.portletStaticText.border .portletHeader {
  color: #FFFFFF;
  background-color: #C9C7C7;
  border-radius: 0;
}

.portletNavigationTree {
  text-align: left;
}

.template-documentviewer .documentFirstHeading {
  width: 75% !important;
  margin-left: auto;
  margin-right: auto;
}

.template-documentviewer a,
.template-documentviewer a + .discreet {
  font-size: 12px;
  color: #000;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
}

.template-documentviewer .pat-documentviewer {
  width: 75% !important;
  margin: 20px auto;
}

.template-documentviewer .DV-fullscreenContainer {
  display: none;
}

.template-login-help:not(.plone-modal-open) .navbar {
  display: none;
}

.template-login-help:not(.plone-modal-open) #content-block {
  justify-content: center;
}

@media (min-width: 992px) {
  .template-login-help:not(.plone-modal-open) #content-block #content {
    max-width: 90% !important;
  }
}

.template-login-help:not(.plone-modal-open) #content {
  margin-top: 80px;
}

.template-login-help:not(.plone-modal-open) #content .row {
  margin-bottom: 80px;
}

.template-login-help:not(.plone-modal-open) #content .row > div:first-child:not(:last-child) {
  border-right: 1px solid #D3D3D3;
}

.template-login-help:not(.plone-modal-open) #content .row > div {
  padding: 0 60px;
}

.template-login-help:not(.plone-modal-open) .subtitle {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.template-login-help:not(.plone-modal-open) .example {
  color: #666666;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
}

.template-login-help:not(.plone-modal-open) form label {
  margin-top: 40px;
}

.template-login-help:not(.plone-modal-open) form input[type="text"] {
  background-color: #F5F5F5;
  border: none;
  box-shadow: none;
  border-radius: 0;
  margin-top: 10px;
}

.template-login-help:not(.plone-modal-open) form [type="submit"] {
  border: 1px solid transparent;
  border-radius: 0;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  box-shadow: none;
  text-shadow: none;
  padding: 10px 20px;
  font-weight: normal;
  background-color: #000;
  color: #FFFFFF;
  border: 1px solid #000;
}

.template-login-help:not(.plone-modal-open) form .field.error {
  margin-top: 20px;
}

.template-login-help:not(.plone-modal-open) form .field.error label {
  margin-top: 0;
}

.template-login-help:not(.plone-modal-open) .trouble a,
.template-login-help:not(.plone-modal-open) .footer a {
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  text-decoration: underline;
}

.template-login-help:not(.plone-modal-open) .trouble a {
  margin-top: 60px;
  display: inline-block;
}

.template-login-help:not(.plone-modal-open) #content-alert .alert {
  padding-bottom: 0;
  margin-bottom: -40px;
}

.template-login-help:not(.plone-modal-open) #content-alert .alert .portalMessage {
  margin-bottom: 0;
}

.template-contact-info #breadcrumb,
.template-contact-info .navbar {
  display: none;
}

.template-contact-info #content-block {
  justify-content: center;
}

@media (min-width: 992px) {
  .template-contact-info #content-block #content {
    max-width: 90% !important;
  }
}

.template-contact-info #content {
  margin-top: 80px;
}

.template-contact-info .documentDescription {
  color: #666;
}

.template-contact-info form label {
  margin-top: 10px;
}

.template-contact-info form textarea,
.template-contact-info form input[type="text"] {
  background-color: #F5F5F5;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.template-contact-info form [type="submit"] {
  border: 1px solid transparent;
  border-radius: 0;
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  box-shadow: none;
  text-shadow: none;
  padding: 10px 20px;
  font-weight: normal;
  background-color: #000;
  color: #FFFFFF;
  border: 1px solid #000;
}

.template-contact-info form .formControls {
  padding: 0;
}

#posplanning-toolbar {
  display: flex;
  flex-wrap: wrap;
}

#posplanning-toolbar .title {
  display: none;
}

#posplanning-toolbar ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-around;
}

#posplanning-toolbar ul li {
  flex: 1;
  border: 1px solid #e5e5e5;
  padding: 5px;
  padding-top: 10px;
}

#posplanning-toolbar ul li:not(:first-child) {
  border-left: none;
}

.section-pos-planning #portal-column-two {
  display: none;
}

.section-pos-planning h1.documentFirstHeading {
  font-size: 20px;
  margin-top: 25px;
  font-family: "ABChanel Corpo_Semi Bold", Helvetica, sans-serif !important;
  text-align: left;
  border-bottom: 4px solid #000;
  padding-bottom: 5px;
  margin-bottom: 0px !important;
  display: flex;
  justify-content: space-between;
}

.section-pos-planning h1.documentFirstHeading img.toggle-all {
  padding: 6px;
  cursor: pointer;
}

.section-pos-planning h1.documentFirstHeading img.toggle-all.visible {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.section-pos-planning h1.documentFirstHeading img.toggle-all.collapsed {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.section-pos-planning .documentByLine {
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

.section-pos-planning .dashboard-tree ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.section-pos-planning .dashboard-tree ul li {
  text-align: left;
  position: relative;
}

.section-pos-planning .dashboard-tree ul li li {
  padding-left: 10px;
}

.section-pos-planning .dashboard-tree ul li .no_prog_in_shop_sc,
.section-pos-planning .dashboard-tree ul li .all_reject_sc,
.section-pos-planning .dashboard-tree ul li .prog_ok_sc {
  color: #F5C142;
}

.section-pos-planning .dashboard-tree ul li .all_reject_no_sc,
.section-pos-planning .dashboard-tree ul li .prog_ok_no_sc {
  color: #019618;
}

.section-pos-planning .dashboard-tree ul li .no_prog_in_shop_no_sc,
.section-pos-planning .dashboard-tree ul li .unknown {
  color: rgba(0, 0, 0, 0.53);
}

.section-pos-planning .dashboard-tree ul li a {
  border-bottom: none !important;
}

.section-pos-planning .dashboard-tree ul li .link-warper {
  padding: 5px 0;
  width: 100%;
}

.section-pos-planning .dashboard-tree ul li .link-warper a {
  border-bottom: none !important;
}

.section-pos-planning .dashboard-tree ul li .level-2 {
  border-bottom: 1px solid black !important;
  margin-bottom: 8px;
  padding-left: 21px;
  padding-bottom: 0px;
  display: block;
}

.section-pos-planning .dashboard-tree ul li .level-2 a {
  text-transform: uppercase;
  font-family: "ABChanel Corpo_Semi Bold", Helvetica, sans-serif;
  font-size: 18px;
  width: unset;
}

.section-pos-planning .dashboard-tree ul li .level-3 {
  border-bottom: 1px solid #CBCBCB !important;
  padding: 10px 0 2px 22px;
  margin-bottom: 10px;
  display: block;
}

.section-pos-planning .dashboard-tree ul li .level-3 a {
  font-family: "Helvetica Neue LT Std 75 Bold", Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  width: unset;
}

.section-pos-planning .dashboard-tree ul li .level-4 {
  padding-left: 25px;
}

.section-pos-planning .dashboard-tree ul li .level-4 a {
  width: unset;
  font-family: "Helvetica Neue LT Std 75 Bold", Helvetica, sans-serif;
  font-size: 14px;
}

.section-pos-planning .dashboard-tree ul li .level-4 a::before {
  content: " ";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-bottom: 1px;
  background-color: #cccccc;
}

.section-pos-planning .dashboard-tree ul li table {
  margin-left: 20px;
  margin-top: 5px;
}

.section-pos-planning .dashboard-tree ul li table tr:nth-child(odd) {
  background-color: #f6f6f6;
}

.section-pos-planning .dashboard-tree ul li table tr td {
  border: none;
  padding: 10px 10px 6px 10px;
}

.section-pos-planning .dashboard-tree ul li table tr td input {
  margin: 0;
}

.section-pos-planning .dashboard-tree ul li table tr td a {
  padding: 0;
}

.section-pos-planning .dashboard-tree ul li .screen {
  padding-left: 10px;
}

.section-pos-planning .dashboard-tree ul li .screen table {
  margin-bottom: 20px;
}

.section-pos-planning .dashboard-tree ul li.carret-level-2 > img {
  position: absolute;
  top: 4px;
  left: -5px;
}

.section-pos-planning .dashboard-tree ul li.carret-level-3 > img {
  position: absolute;
  top: 6px;
  left: 4px;
}

.section-pos-planning .dashboard-tree ul li.carret-level-4 > img {
  position: absolute;
  top: -1px;
  left: 7px;
}

.section-pos-planning .dashboard-tree ul li img.carret {
  padding: 6px;
  cursor: pointer;
}

.section-pos-planning .dashboard-tree ul li img.carret.visible {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.section-pos-planning .dashboard-tree ul li img.carret.collapsed {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.section-pos-planning .dashboard-tree ul li .visible + div + ul,
.section-pos-planning .dashboard-tree ul li .visible + div + div {
  display: unset;
}

.section-pos-planning .dashboard-tree ul li .collapsed + div + ul,
.section-pos-planning .dashboard-tree ul li .collapsed + div + div {
  display: none;
}

.section-pos-planning .dashboard-tree > ul > li:not(:last-child) {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}

.section-pos-planning #screens {
  margin-top: 15px;
}

.section-pos-planning #screens div.screen:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}

.section-pos-planning .card {
  flex: 0 0 calc(100% / 6);
  max-width: calc(100% / 6);
  padding: 0.5rem;
}

@media (max-width: 1400px) {
  .section-pos-planning .card {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }
}

@media (max-width: 1200px) {
  .section-pos-planning .card {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }
}

@media (max-width: 992px) {
  .section-pos-planning .card {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
}

@media (max-width: 768px) {
  .section-pos-planning .card {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
  }
}

@media (max-width: 480px) {
  .section-pos-planning .card {
    flex: 0 0 calc(100%);
    max-width: calc(100%);
  }
}

.section-pos-planning .card-shadow {
  display: flex !important;
  flex-direction: column;
  position: relative;
}

.section-pos-planning .card-shadow .card-link .card-img-link img.card-img-top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.section-pos-planning .card-shadow .programme-delete,
.section-pos-planning .card-shadow .informations-icon {
  margin: 0.7rem;
  position: absolute;
}

.section-pos-planning .card-shadow .programme-delete {
  left: 0;
  z-index: 5;
}

.section-pos-planning .card-shadow .programme-delete .icon {
  position: absolute;
  left: 0;
  pointer-events: none;
}

.section-pos-planning .card-shadow .programme-delete input.delete {
  border: 0;
  padding: 11px;
  height: 8px;
}

.section-pos-planning .card-shadow .programme-delete input.delete:hover {
  border: 0 !important;
}

.section-pos-planning .card-shadow .informations-text {
  position: absolute;
  display: none;
  padding: 43px 26px;
  background-color: #fff;
  inset: 0;
  z-index: 5;
  text-align: left;
  overflow-y: auto;
  font-size: 14px;
}

.section-pos-planning .card-shadow .informations-text:hover {
  display: block;
}

.section-pos-planning .card-shadow .informations-icon {
  right: 0;
  top: 0;
  z-index: 6;
}

.section-pos-planning .card-shadow .informations-icon:hover + .informations-text {
  display: block;
}

.section-pos-planning .card-list {
  margin-bottom: 2rem;
  flex-flow: row;
  flex-wrap: wrap;
}

.section-pos-planning .card-list .card {
  margin-bottom: 2rem;
}

.section-pos-planning div.video-title {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  text-shadow: none;
  font-size: 12px;
  margin-top: 20px;
  color: #6d6d6d;
  padding: 0 2rem;
  white-space: normal;
  text-align: center;
  width: 100%;
}

.section-pos-planning div.video-title::after {
  content: " ";
  display: block;
  width: 70%;
  margin: 15px auto 15px auto;
  border-bottom: 1px solid #cccccc;
}

.section-pos-planning div.screen-title {
  text-align: left;
  text-transform: uppercase;
  display: list-item !important;
  list-style: disc;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 14px;
  margin-left: 11px;
  margin-bottom: 14px;
}

.section-pos-planning div.programme-status {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 2rem;
  margin-top: auto;
  width: 100%;
}

.section-pos-planning div.programme-status > div {
  position: relative;
  width: calc(100% / 3);
}

.section-pos-planning div.programme-status > div input {
  opacity: 0;
  z-index: 1;
  position: relative;
  padding: 0;
  width: 25px;
  overflow: hidden;
}

.section-pos-planning div.programme-status > div input + div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.section-pos-planning div.programme-status > div input + div::before {
  content: " ";
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  display: block;
}

.section-pos-planning div.programme-status > div input.accept + div::before {
  background-image: url(../img/CHECK.svg);
}

.section-pos-planning div.programme-status > div input.accept.active + div::before, .section-pos-planning div.programme-status > div input.accept:hover + div::before {
  background-image: url(../img/CHECK_CLIC.svg);
}

.section-pos-planning div.programme-status > div input.reject + div::before {
  background-image: url(../img/CROSS.svg);
}

.section-pos-planning div.programme-status > div input.reject.active + div::before, .section-pos-planning div.programme-status > div input.reject:hover + div::before {
  background-image: url(../img/CROSS_CLIC.svg);
}

.section-pos-planning div.programme-status > div input.reset + div::before {
  background-image: url(../img/RESET.svg);
}

.section-pos-planning div.programme-dates {
  margin-top: 1rem;
  margin-bottom: 25px;
  padding: 0 2rem;
  width: 100%;
}

.section-pos-planning div.programme-dates fieldset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.section-pos-planning div.programme-dates fieldset label {
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-weight: normal;
  font-size: 12px;
  margin: 0;
}

.section-pos-planning div.programme-dates fieldset .programme-date {
  margin: 0 0 0 0.2rem;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 12px;
  color: #6d6d6d;
}

.section-pos-planning div.programme-dates fieldset .pattern-pickadate-wrapper {
  margin: 0 0 0 0.2rem;
}

.section-pos-planning div.programme-dates fieldset .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper input {
  width: auto;
  margin: 0;
  background-color: transparent;
  border: none !important;
  box-shadow: none !important;
  padding: 0;
  width: 100%;
  text-align: right;
  height: auto;
}

.section-pos-planning div.programme-applied, .section-pos-planning div.special-request-applied, .section-pos-planning span.shop-applied {
  text-align: center;
  margin: -1rem 0 1rem 0;
}

.section-pos-planning div.programme-applied input, .section-pos-planning div.special-request-applied input, .section-pos-planning span.shop-applied input {
  opacity: 0;
  height: 0;
  width: 0;
}

.section-pos-planning div.programme-applied input + label::before, .section-pos-planning div.special-request-applied input + label::before, .section-pos-planning span.shop-applied input + label::before {
  content: " ";
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  display: block;
  background-image: url(../img/CHECK.svg);
}

.section-pos-planning div.programme-applied input:checked + label::before, .section-pos-planning div.special-request-applied input:checked + label::before, .section-pos-planning span.shop-applied input:checked + label::before {
  background-image: url(../img/CHECK_CLIC.svg);
}

.section-pos-planning span.shop-applied {
  position: absolute;
}

@-moz-document url-prefix() {
  .section-pos-planning span.shop-applied {
    margin-left: 0px !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .section-pos-planning span.shop-applied {
    margin-left: 7px;
  }
}

.section-pos-planning span.shop-applied input + label::before {
  background-image: url(../img/CHECK_SHOP.svg);
  width: 19px;
}

.section-pos-planning span.shop-applied:checked + label::before {
  width: 19px;
}

.section-pos-planning .level-4.link-warper span.shop-applied {
  top: 14px;
}

.section-pos-planning .level-3.link-warper span.shop-applied {
  top: 21px;
}

.section-pos-planning .level-2.link-warper span.shop-applied {
  top: 18px;
}

.section-pos-planning div.special-request .card-shadow {
  display: flex;
  flex-direction: column;
}

.section-pos-planning div.special-request .comments {
  flex: 1;
  position: relative;
  overflow-y: auto;
  margin: 1rem;
  min-height: 150px;
}

.section-pos-planning div.special-request .comments ul {
  position: absolute;
  top: 0;
  left: 0;
  list-style: none;
  width: 100%;
  padding: 0;
  text-align: left;
}

.section-pos-planning div.special-request .comments ul li {
  background-color: #f6f6f6;
  color: #6d6d6d;
  font-size: 14px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.section-pos-planning div.special-request .new-request {
  display: flex;
  position: relative;
  margin: 0 1rem 1rem 1rem;
  background-color: #000;
}

.section-pos-planning div.special-request .new-request input[type="text"] {
  background-color: #f9f9f9;
  border: 1px solid #f2f2f4;
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  border-radius: 0;
  box-shadow: none;
  padding-top: 9px;
}

.section-pos-planning div.special-request .new-request input[type="button"] {
  background-color: transparent;
  color: transparent;
  padding: 0 5px;
  position: relative;
  z-index: 1;
}

.section-pos-planning div.special-request .new-request::before {
  background-image: url(../img/SEND_ARROW.svg);
  display: block;
  position: absolute;
  content: " ";
  width: 20px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 50%;
  transform: translate(50%, -50%);
  right: 16px;
}

.section-pos-planning .programme-actions {
  margin-top: 2rem;
}

.section-pos-planning .programme-actions input {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  padding: 12px 21px;
  border: none !important;
}

.section-pos-planning .programme-actions input.submit {
  background-color: #000;
  color: #FFFFFF;
}

.section-pos-planning .programme-actions input.submit:hover, .section-pos-planning .programme-actions input.submit:focus, .section-pos-planning .programme-actions input.submit:active {
  background-color: #d8d8d8;
}

.section-pos-planning .programme-actions input.submit[disabled="disabled"] {
  background-color: #f5f5f5;
  color: #6d6d6d;
  cursor: not-allowed;
  opacity: 0.5;
}

.section-pos-planning .programme-actions input.reset {
  background-color: #f5f5f5;
  color: #6d6d6d;
}

.section-pos-planning .posplanning-listing a.button {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  background-color: #000;
  color: #FFFFFF;
  padding: 10px 20px;
  align-self: center;
  margin-top: 12px;
  display: inline-block;
}

.section-pos-planning .posplanning-listing .form-inline {
  flex-direction: column;
}

.section-pos-planning .posplanning-listing .form-inline .row {
  width: 100%;
}

.section-pos-planning .modal-dialog .video-js {
  max-width: 100%;
}

.section-pos-planning .tree ul li table tr.programme {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.section-pos-planning.userrole-manager .tree ul li table tr.programme {
  display: grid;
  grid-template-columns: 20px 1fr 1fr 1fr;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen .card-list {
  margin-bottom: 2rem;
  flex-flow: column;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen .card-list .card {
  margin-bottom: 0.5rem;
  flex: unset;
  padding: 0;
  max-width: 100%;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen .card-list .programme {
  height: 67px;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen .card-shadow {
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-radius: 0px !important;
  border: 1px solid #CBCBCB;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  justify-content: space-between;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen .card-shadow .card-link {
  padding: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen .card-shadow .card-link img {
  display: none;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.video-title {
  margin-top: 0px;
  text-align: start;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.video-title::after {
  content: "";
  display: absolute;
  width: unset;
  margin: 0px;
  border-bottom-width: 0px;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.programme-dates {
  width: 29%;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.programme-dates fieldset {
  width: 50%;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.programme-dates fieldset .programme-date {
  margin: 0;
  color: #6D6D6D;
  width: 100%;
  text-align: center;
  margin: 0px 19px;
  height: 27px;
  background-color: #F6F6F6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.special-request {
  min-height: 81px;
  max-height: 132px;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.special-request .card-shadow {
  border-width: 0px !important;
  height: 100%;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.special-request .comments {
  flex: unset;
  min-height: unset;
  max-height: 100px;
  width: 70%;
  margin-right: 51px;
}

.section-pos-planning:not(.userrole-manager) .dashboard-tree .screen div.special-request .comments ul {
  position: relative;
}

.template-facetednavigation_view #content-core {
  width: 100%;
}

.template-facetednavigation_view #content-core > div {
  width: 100%;
}

.template-facetednavigation_view div.faceted-area.left-area {
  float: none;
  width: auto;
  padding: 2.5rem 1.5rem 0 2.5rem;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js {
  margin-left: 0;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset .widget-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset legend {
  display: none;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset dl {
  display: inline-flex;
  flex-wrap: wrap;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset dd,
.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset dt {
  margin: 0;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset dt,
.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset dd > span {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-right: 0.5rem;
  font-weight: normal;
  line-height: normal;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset dt span,
.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset dd > span span {
  padding-right: 0.2em;
}

.template-facetednavigation_view #faceted-form div.faceted-area.center-area .left-area-js fieldset.widget-fieldset dd > span {
  margin-top: 4px;
}

.template-facetednavigation_view .select2-drop {
  color: #6d6d6d;
}

.template-facetednavigation_view .select2-drop-active {
  border: 1px solid #f6f6f6;
  margin-top: 0.5rem;
}

.template-facetednavigation_view .select2-drop-active .select2-results {
  margin: 0;
  padding: 0;
}

.template-facetednavigation_view .select2-drop-active .select2-results .select2-result-label,
.template-facetednavigation_view .select2-drop-active .select2-results .select2-no-results {
  padding: 10px 4px 4px 4px;
}

.template-facetednavigation_view .select2-drop-active .select2-results .select2-highlighted {
  background-color: #f6f6f6;
  color: #000;
}

.template-facetednavigation_view .select2-container-multi .select2-choices {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent;
  background-image: none;
}

.template-facetednavigation_view .select2-container-multi .select2-choices .select2-search-field {
  border: 1px solid #e8e8e8 !important;
  background-color: #fcfcfc;
  box-shadow: none !important;
  background-image: none;
  min-height: 32px;
  grid-column: span 2;
  position: relative;
}

.template-facetednavigation_view .select2-container-multi .select2-choices .select2-search-field input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  border: none !important;
}

.template-facetednavigation_view .select2-container-multi .select2-choices .select2-search-choice {
  background-color: #f6f6f6 !important;
  color: #6d6d6d;
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 6px;
}

.template-facetednavigation_view .select2-container-multi .select2-choices .select2-search-choice:first-child:nth-last-child(2), .template-facetednavigation_view .select2-container-multi .select2-choices .select2-search-choice:nth-child(odd):nth-last-child(2) {
  grid-column: span 2;
}

.template-facetednavigation_view .select2-container-multi .select2-choices .select2-search-choice div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.1rem 0.5rem 0.7rem 0.5rem;
  font-size: 12px;
}

.template-facetednavigation_view .select2-container-multi .select2-choices .select2-search-choice div::before {
  margin-bottom: 4px;
  margin-right: 5px;
}

.template-facetednavigation_view .select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close {
  height: 100%;
  width: 100%;
  background-image: none !important;
  top: 0;
  left: 0;
}

.template-facetednavigation_view fieldset.widget-fieldset {
  padding: 0 0 2rem;
}

.template-facetednavigation_view fieldset.widget-fieldset legend {
  font-family: "ABChanel Corpo_Semi Bold", Helvetica, sans-serif;
  font-size: 13px;
  text-align: left;
  padding: 0;
  margin: 0;
  position: relative;
  border: none;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 400;
}

.template-facetednavigation_view fieldset.widget-fieldset legend::after {
  content: "";
  background: url("../img/dropdown-caret.svg") no-repeat center center;
  border: none;
  width: 15px;
  height: 15px;
  transition: 0.3s;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s;
}

.template-facetednavigation_view fieldset.widget-fieldset form {
  transition: height 0.35s ease;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
}

.template-facetednavigation_view fieldset.widget-fieldset form ul {
  padding: 0;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
}

.template-facetednavigation_view fieldset.widget-fieldset form ul li div,
.template-facetednavigation_view fieldset.widget-fieldset form ul li label {
  font-family: "ABChanel Corpo_Light", Helvetica, sans-serif;
}

.template-facetednavigation_view fieldset.widget-fieldset form ul li label {
  background-color: #f6f6f6;
  color: #6d6d6d;
  width: 100%;
  padding: 0;
  margin: 0;
  max-width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  height: 100%;
  font-size: 12px;
  line-height: 14px;
  overflow-wrap: anywhere;
  padding: 1rem 0.5rem 0.8rem 0.5rem;
  cursor: pointer;
}

.template-facetednavigation_view fieldset.widget-fieldset form ul li input {
  display: none;
}

.template-facetednavigation_view fieldset.widget-fieldset form ul li input:checked + label {
  background-color: #d8d8d8;
  color: #FFFFFF;
}

.template-facetednavigation_view fieldset.widget-fieldset form ul li:nth-child(odd):last-child {
  grid-column: span 2;
}

.template-facetednavigation_view fieldset.widget-fieldset form input[type="text"] {
  border: 1px solid #e8e8e8 !important;
  background-color: #fcfcfc;
}

.template-facetednavigation_view fieldset.widget-fieldset form .searchButton {
  position: absolute;
  top: 0;
  right: 2px;
  height: 34px;
  width: 34px;
  background-image: url(../img/loupe.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #d8d8d8;
  border: 0px !important;
}

.template-facetednavigation_view fieldset.widget-fieldset.display legend::after {
  transform: rotate(-180deg);
}

.template-facetednavigation_view fieldset.widget-fieldset:not(.display) form {
  height: 0;
}

.template-facetednavigation_view .section-status li[id*="published"],
.template-facetednavigation_view .section-status li[id*="private"] {
  display: none;
}

.template-facetednavigation_view .section-status li[id*="_validated"] {
  grid-row: 1;
}

.template-facetednavigation_view .section-axis li[id*="LED"],
.template-facetednavigation_view .section-axis li[id*="Led"] {
  display: none;
}

.template-facetednavigation_view .faceted-criteria-reset a::before,
.template-facetednavigation_view .faceted-remove::before {
  background-image: url(../img/close.svg);
  background-size: contain;
  height: 10px;
  width: 10px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  margin-bottom: 2px;
}

.template-facetednavigation_view .faceted-criteria-hide-show {
  display: none;
}

.template-facetednavigation_view .faceted-criteria-reset {
  display: inline;
}

.template-facetednavigation_view .faceted-criteria-reset a {
  color: #212529 !important;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.template-facetednavigation_view .faceted-criteria-reset a::before {
  margin-left: 5px;
}

.template-facetednavigation_view #portlet-burger {
  display: grid;
  grid-template-columns: 20vw 20vw;
}

@media (max-width: 768px) {
  .template-facetednavigation_view #portlet-burger {
    grid-template-columns: 100vw 100vw;
  }
}

.template-facetednavigation_view #portlet-burger > * {
  transition: transform 0.5s;
}

.template-facetednavigation_view #portlet-burger > .nav.active,
.template-facetednavigation_view #portlet-burger > #left-area:not(.active) {
  transform: translateX(0);
}

.template-facetednavigation_view #portlet-burger > .nav:not(.active),
.template-facetednavigation_view #portlet-burger > #left-area.active {
  transform: translateX(-20vw);
}

@media (max-width: 768px) {
  .template-facetednavigation_view #portlet-burger > .nav:not(.active),
  .template-facetednavigation_view #portlet-burger > #left-area.active {
    transform: translateX(-100vw);
  }
}

.template-facetednavigation_view #portlet-burger > aside {
  display: none;
}

.template-listing_view #content .documentByLine {
  display: none;
}

.template-listing_view #content h1.documentFirstHeading {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
}

.template-listing_view #content #content-core div.entries article.entry {
  margin-bottom: 26px;
}

.template-listing_view #content #content-core div.entries article.entry span.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-listing_view #content #content-core div.entries article.entry span.summary div.title-description {
  text-align: left;
}

@media (max-width: 768px) {
  .template-listing_view #content #content-core div.entries article.entry span.summary {
    flex-direction: column;
    justify-content: center;
  }
}

.template-listing_view #content #content-core div.entries article.entry span.summary a {
  border-bottom-width: 0px;
}

.template-listing_view #content #content-core div.entries article.entry span.summary a[class*="contenttype-"] {
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: normal;
}

.template-listing_view #content #content-core div.entries article.entry span.summary a[class*="contenttype-"]::before {
  content: "";
  width: 0px;
  margin-right: 0px;
}

.template-listing_view #content #content-core div.entries article.entry span.summary div.description,
.template-listing_view #content #content-core div.entries article.entry span.summary div.document_byline {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}

.template-listing_view #content #content-core div.entries article.entry span.summary img {
  width: 179px;
  height: 107px;
  max-width: none;
  margin-left: 200px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .template-listing_view #content #content-core div.entries article.entry span.summary img {
    margin-left: 0px;
  }
}

.template-summary_view #content .tileFooter {
  display: none;
}

.template-summary_view #content .documentByLine {
  display: none;
}

.template-summary_view #content h1.documentFirstHeading {
  font-family: "ABChanel Corpo_Regular", sans-serif;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
}

.template-summary_view #content article.tileItem {
  margin-bottom: 48px;
}

.template-summary_view #content article.tileItem .tileHeadline {
  text-align: center;
  margin-bottom: 0px;
}

.template-summary_view #content article.tileItem .tileHeadline a {
  font-family: "Helvetica Neue LT Std 55 Roman", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: normal;
}

.template-summary_view #content article.tileItem .tileHeadline a:hover {
  text-decoration: underline;
}

.template-summary_view #content article.tileItem .tileBody {
  text-align: center;
}

.template-summary_view #content article.tileItem .tileBody span {
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}

.template-twofold_view header {
  width: 100%;
}

.template-twofold_view #content-core {
  width: 100%;
}

.template-twofold_view #content-core #content-twofold {
  width: 100%;
}

@media (max-width: 480px) {
  .template-twofold_view #content-core #content-twofold {
    width: 80%;
  }
}

.template-twofold_view #content-core #content-twofold .folder {
  margin: 42px 0 59px;
}

.template-twofold_view #content-core #content-twofold .folder h2.headline {
  margin-bottom: 0px;
}

.template-twofold_view #content-core #content-twofold .folder h2.headline a {
  font-size: 23px;
}

.template-twofold_view #content-core #content-twofold .folder div.description {
  margin-top: 2px;
}

.template-twofold_view #content-core #content-twofold .folder section.content-gallery {
  margin-top: 5px;
}

.template-twofold_view #content-core #content-twofold .folder section.content-gallery #thumbnails {
  padding-left: 0;
  padding-right: 0;
}

.template-full_view #content h2.headline {
  font-size: 18px;
  line-height: 26px;
  margin-top: 52px;
  margin-bottom: 0;
  font-weight: bold;
}

.template-full_view #content #plone-document-byline {
  margin: 0px;
}

.template-full_view #content .has-table {
  overflow: auto;
  margin-top: 10px;
}

.template-full_view #content .has-table table {
  margin-bottom: 11px;
}

.template-full_view #content .has-table table td,
.template-full_view #content .has-table table th {
  vertical-align: middle;
}

.template-full_view #content .has-table table th {
  font-family: "Helvetica Neue LT Std 65 Medium", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}

.template-full_view #content .has-table table td {
  vertical-align: middle;
  text-align: left;
  padding: 17px 20px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
}

.template-full_view #content .has-table table td a {
  word-break: break-all;
}

.template-full_view #content .has-table table td a:before {
  content: "";
  width: 0px;
  height: 0px;
  margin: 0px;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  font-family: "Helvetica Neue LT Std 45 Light", Helvetica, sans-serif;
  text-align: center;
  font-weight: lighter;
}

.template-full_img .outer-wrapper {
  min-height: 100vh;
  overflow: hidden;
}

.outer-wrapper {
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
}

#portal-footer-signature,
#portal-colophon {
  display: none;
}

#portal-footer-wrapper {
  background-color: transparent;
  padding: 0;
}

#portal-footer-wrapper .row {
  margin: 0;
}

#portal-footer-wrapper .row .portletWrapper:first-child {
  border-bottom: none;
  margin: 0;
  padding: 0;
}

#portal-footer-wrapper .row div {
  min-height: 0px;
}

.plone-toolbar-left #spotlight .arrow-right {
  left: unset;
  right: 150px;
}
